import axiosInstance from '../../api/axiosInstance';

const state = () => ({
  autoBrands: [],
  auto: [],
  autosByVIN: [],
  brandsByNumber: null,
  modifications: [],
  categories: [],
  articles: [],
  catalogType: 'brand',
  tabTypeBrand: 'favorites',
  loading: false,
  offline: false,
  modId: '66',
  modelId: null,
  manufacturerId: null,
  hasSearchRequest: false,
  favoritesBrands: {
    A: [{ id: 5, name: 'AUDI' }],
    B: [{ id: 16, name: 'BMW' }, { id: 3122, name: 'BYD' }],
    C: [{ id: 138, name: 'CHEVROLET' },
      { id: 21, name: 'CITROEN' }],
    D: [{ id: 139, name: 'DACIA' },
      { id: 185, name: 'DAEWOO' },
      { id: 807, name: 'DATSUN' }],
    E: [{ id: 3332, name: 'EMGRAND' }],
    F: [{ id: 35, name: 'FIAT' },
      { id: 36, name: 'FORD' }],
    G: [{ id: 148, name: 'GAZ' },
      { id: 2590, name: 'GEELY' },
      { id: 2903, name: 'GREAT WALL' }],
    H: [{ id: 45, name: 'HONDA' },
      { id: 183, name: 'HYUNDAI' }],
    I: [{ id: 1526, name: 'INFINITI' }],

    J: [{ id: 882, name: 'JEEP' }],
    K: [{ id: 184, name: 'KIA' }],
    L: [{ id: 63, name: 'LADA' },
      { id: 1820, name: 'LAND ROVER' },
      { id: 842, name: 'LEXUS' },
      { id: 3086, name: 'LIFAN' }],
    M: [{ id: 72, name: 'MAZDA' },
      { id: 74, name: 'MERCEDES-BENZ' },
      { id: 77, name: 'MITSUBISHI' }],
    N: [{ id: 80, name: 'NISSAN' }],
    O: [{ id: 84, name: 'OPEL' }],
    P: [{ id: 88, name: 'PEUGEOT' },
      { id: 92, name: 'PORSCHE' }],
    R: [{ id: 93, name: 'RENAULT' }],
    S: [{ id: 106, name: 'SKODA' },
      { id: 107, name: 'SUBARU' },
      { id: 109, name: 'SUZUKI' }],
    T: [{ id: 111, name: 'TOYOTA' }],
    U: [{ id: 1553, name: 'UAZ' }],
    V: [{ id: 120, name: 'VOLVO' },
      { id: 121, name: 'VW' }]

  }
});

const mutations = {
  SET_MOD_ID(state, modID) {
    state.modId = modID;
  },
  SET_MODEL_ID(state, modelId) {
    state.modelId = modelId;
  },
  SET_MONUFACTURER_ID(state, manufacturerId) {
    state.manufacturerId = manufacturerId;
  },
  SET_TECDOC_LOADING_STATUS(state, loading) {
    state.loading = loading;
  },
  SET_TECDOC_IS_OFFLINE(state, offline) {
    state.offline = offline;
  },
  SET_TAB_TYPE_BRAND(state, tabTypeBrand) {
    state.tabTypeBrand = tabTypeBrand;
  },
  SET_AUTO_BY_VIN(state, autosByVIN) {
    state.autosByVIN = autosByVIN;
  },
  SET_BRANDS_BY_NUMBER(state, brandsByNumber) {
    state.brandsByNumber = brandsByNumber;
  },
  SET_CATALOG_TYPE(state, type) {
    state.catalogType = type;
  },
  SET_AUTO_BRAND(state, autoBrands) {
    state.autoBrands = autoBrands;
  },
  SET_AUTO(state, auto) {
    state.auto = auto;
  },
  SET_MODIFICATIONS(state, modifications) {
    state.modifications = modifications;
  },
  SET_TECDOC_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_ARTICLES_SIMPLIFIED(state, articles) {
    state.articles = articles;
  },
  SET_HAS_SEARCH_REQUEST(state, hasSearchRequest) {
    state.hasSearchRequest = hasSearchRequest;
  },
};

const actions = {
  async GetAutoBrand({ commit, state }, { carType }) {
    commit('SET_TECDOC_LOADING_STATUS', true);

    const url = carType ? `/api/tecDoc?address=manufacturers&parameters[0]=carType=${carType}` : '/api/tecDoc?address=manufacturers';
    await axiosInstance({
      url
    })
      .then((data) => {
        commit('SET_TECDOC_LOADING_STATUS', false);
        commit('SET_AUTO_BRAND', data || []);
        if (data === '') {
          commit('SET_TECDOC_IS_OFFLINE', true);
        } else if (state.offline) {
          commit('SET_TECDOC_IS_OFFLINE', false);
        }
      });
  },
  async GetAutoByBrand({ commit }, { manufacturerId }) {
    commit('SET_TECDOC_LOADING_STATUS', true);
    commit('SET_MONUFACTURER_ID', manufacturerId);

    await axiosInstance({
      url: `/api/tecDoc?address=models&parameters[0]=manufacturerId=${manufacturerId}`,
    })
      .then((data) => {
        commit('SET_AUTO', data || []);
        commit('SET_CATALOG_TYPE', 'auto');
        commit('SET_TECDOC_LOADING_STATUS', false);
      });
  },
  async GetModifications({ commit }, { manufacturerId, modelId }) {
    commit('SET_TECDOC_LOADING_STATUS', true);

    commit('SET_MODEL_ID', modelId);
    commit('SET_MONUFACTURER_ID', manufacturerId);

    await axiosInstance({
      url: `/api/tecDoc?address=modifications&parameters[0]=manufacturerId=${manufacturerId}&parameters[1]=modelId=${modelId}`,
    })
      .then((data) => {
        commit('SET_MODIFICATIONS', data || []);
        commit('SET_CATALOG_TYPE', 'modification');
        commit('SET_TECDOC_LOADING_STATUS', false);
      });
  },
  async GetTecdocCategories({ commit }, { modificationId }) {
    commit('SET_TECDOC_LOADING_STATUS', true);
    commit('SET_MOD_ID', modificationId);

    await axiosInstance({
      url: `/api/tecDoc?address=tree&parameters[0]=modificationId=${modificationId}`,
    })
      .then((data) => {
        commit('SET_TECDOC_CATEGORIES', data || []);
        commit('SET_CATALOG_TYPE', 'categories');
        commit('SET_TECDOC_LOADING_STATUS', false);
      });
  },
  async GetArticlesSimplified({ commit }, { modificationId, categoryId }) {
    commit('SET_TECDOC_LOADING_STATUS', true);
    commit('SET_MOD_ID', modificationId);

    await axiosInstance({
      url: `/api/tecDoc?address=articlesSimplified&parameters[0]=modificationId=${modificationId}&parameters[1]=categoryId=${categoryId}`,
    })
      .then((data) => {
        commit('SET_ARTICLES_SIMPLIFIED', data);
        commit('SET_CATALOG_TYPE', 'articles');
        commit('SET_TECDOC_LOADING_STATUS', false);
      });
  },
  async GetAutoByVin({ commit }, { vin }) {
    return await axiosInstance({
      url: `/api/tecDoc?address=getVehiclesByVIN&parameters[0]=vin=${vin.toLowerCase()}`,
    })
      .then((data) => {
        commit('SET_AUTO_BY_VIN', data);
        // commit(SET_CATALOG_TYPE, 'vin');
        return data;
      })
      .catch((error) => {
        console.log(error);
        return Promise.reject(error);
      });
  },
  async GetBrandsByNumber({ commit }, { number }) {
    return await axiosInstance({
      url: `/api/tecDoc/brands?number=${number.toLowerCase()}`,
    })
      .then((data) => {
        commit('SET_BRANDS_BY_NUMBER', data);
        return data;
      })
      .catch((error) => {
        console.log(error);
        commit('SET_BRANDS_BY_NUMBER', null);
        return Promise.reject(error);
      });
  },
  async GetArticleByBrandAndNumber(_, { article, brand }) {
    return axiosInstance({
      url: `/api/tecDoc?address=articleByBrandAndNumber2&parameters[0]=brand=${brand}&parameters[1]=number=${article}`
    });
  },
  async getAdaptabilityManufacturers(_, { article, brand }) {
    return axiosInstance({
      url: `/api/tecDoc?address=adaptabilityManufacturers&parameters[0]=brandName=${brand}&parameters[1]=number=${article}`
    });
  },
  async getAdaptabilityModels(_, { article, brand, manufacturerName }) {
    return axiosInstance({
      url: `/api/tecDoc?address=adaptabilityModels&parameters[0]=brandName=${brand}&parameters[1]=number=${article}&parameters[2]=manufacturerName=${manufacturerName}`
    });
  },
  async getAdaptabilityModifications(_, {
    article, brand, manufacturerName, modelName
  }) {
    return axiosInstance({
      url: `/api/tecDoc?address=adaptabilityModifications&parameters[0]=brandName=${brand}&parameters[1]=number=${article}&parameters[2]=manufacturerName=${manufacturerName}&parameters[3]=modelName=${modelName}`
    });
  },
};

const getters = {
  autoBrands: (state) => {
    const autoBrands = {};
    state.autoBrands.forEach((item) => {
      if (autoBrands[item.name[0]]) {
        autoBrands[item.name[0]].push(item);
      } else {
        autoBrands[item.name[0]] = [item];
      }
    });

    if (state.tabTypeBrand === 'favorites') {
      return state.favoritesBrands;
    }

    if (state.tabTypeBrand === 'all' || state.tabTypeBrand === 'custom') {
      return autoBrands;
    }
    const result = {};
    result[state.tabTypeBrand] = autoBrands[state.tabTypeBrand];

    return result;
  },
  categories: (state) => {
    const cats = state.categories.map((item) => {
      if (item.parentId === null) {
        item.parentId = 0;
      }

      return item;
    });
    function createTree(data, idField, parentField, rootParent) {
      const tree = {};
      const obj = {};

      data.forEach((n) => {
        obj[n[idField]] = Object.assign({}, n);
      });

      Object.values(obj).forEach((n) => {
        if (n[parentField] === rootParent) {
          tree[n[idField]] = n;
        } else {
          const t = obj[n[parentField]];
          t.children = t.children || {};
          t.children[n[idField]] = n;
        }
      });

      return tree;
    }

    const tree = createTree(cats, 'id', 'parentId', 0);

    const serialize = (node) => {
      if (node.children) {
        node.children = Object.values(node.children);

        node.children.forEach((item) => {
          if (item.children) {
            serialize(item);
          }
        });
      }
      return node;
    };

    return Object.values(tree).map((item) => {
      if (item.children) {
        serialize(item);
      }

      return item;
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
