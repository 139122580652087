<template>
  <el-container class="part-page">
    <app-aside />

    <el-main class="part-page__wrapper">
      <div class="part-page__body">
        <div class="part-page__header">
          <h2>
            {{ $route.params.brand }} (OEM: {{ $route.params.article }})
          </h2>
          <div>
            <div
              v-if="suppliersLoading"
              class="loading-indicator"
            >
              Загрузка
            </div>
            <el-button
              class="breadcrumb-container__button"
              size="mini"
              icon="el-icon-s-tools"
              @click="$store.commit('ui/SET_SHOW_SETTINGS', true)"
            >
              Настройки
            </el-button>
          </div>
        </div>
        <n-block-table
          v-model="activeRowId"
          :data="{
            products: {
              name: 'Запрашиваемый артикул',
              data: sortedProducts,
              color: '#4F8D12',
              background: '#F4FBED',
            },
            analogs: {
              name: 'Аналоги',
              data: sortedAnalogs,
              color: '#BF8C00',
              background: '#FCF8ED',
            }
          }"
          :columns="[
            { name: '', prop: 'expandProp', width: '30px', align: 'center' },
            { name: 'Артикул', prop: 'article', width: windowSize.width > 700 ? '100px' : '60px', sortable: true, show: showColumns.hide, translucent: tableVisual.focus },
            { name: 'Бренд', prop: 'brand', width: '130px', sortable: true, show: showColumns.hide2, translucent: tableVisual.focus },
            { name: '', prop: 'info', width: '50px', align: 'center' },
            { name: 'Название', prop: 'name', width: '1fr', sortable: true },
            { name: 'Поставщик', prop: 'supplier', width: '150px', show: _role === 'admin' },
            { name: 'ID склада', prop: 'guid', width: '150px', show: _role === 'admin' },
            { name: 'Склад', prop: 'stockName', width: '150px' },
            { name: 'Цена', prop: 'price', width: windowSize.width > 700 ? '90px' : '75px', align: 'right', sortable: true },
            { name: 'Доставка', prop: 'deliveryDate', width: '90px', align: 'center', sortable: true, translucent: tableVisual.focus },
            { name: 'Остаток', prop: 'count', width: '86px', align: 'right', translucent: tableVisual.focus },
            { name: 'Купить', prop: 'bay', width: '122px', align: 'center' },
          ]"
          :main-columns="mainColumns"
          class="part-table"
          :loading="loading"
          :default-sort="sort"
          :row-class="rowClassName"
          row-index-key="guid"
          @sort-change="productsSort"
        >
          <template #expandProp="{ row }">
            <span
              v-if="row.stocks.length > 1"
              class="expand-button"
              @click="changeExpand(row)"
            >
              <i
                v-if="!row.expand"
                class="el-icon-arrow-right"
              />
              <i
                v-else
                class="el-icon-arrow-down"
              />
            </span>
          </template>
          <template #info="{ row }">
            <el-button
              icon="el-icon-search"
              size="mini"
              circle
              @click.stop="showInfoProduct(row)"
            />
          </template>
          <template #name="{ row }">
            <span
              class="name-row"
              @click="changeExpand(row)"
            >
              {{ row.name }}
            </span>
          </template>
          <template #price="{ row }">
            <span v-if="row.stocks.length === 1">
              <priceOutput :price="normalizePriceMarkup(getPrice(row.stocks[0]))" />
            </span>
            <span v-else>
              от <priceOutput :price="arrayMin(row.stocks)" />
            </span>
          </template>
          <template #guid="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              placement="top-start"
            >
              <template #content>
                <div style="display: flex">
                  <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;width: 100px;">
                    {{ String(row.guid) }}
                  </div>
                  <div>
                    <i
                      class="el-icon-document-copy"
                      style="cursor: pointer"
                      @click="copy(row.guid)"
                    />
                  </div>
                </div>
              </template>
              <div
                class="cell"
                style="width: 115px;"
              >
                {{ skladId(String(row.guid)) }}
              </div>
            </el-tooltip>
          </template>
          <template #stockName="{ row }">
            <div
              v-if="row.stocks.length > 1 && !row.expand"
              style="color: #1C6A9E;cursor: pointer;display: inline; border-bottom: 1px dashed rgba(28, 106, 158, 0.5);"
              @click="changeExpand(row)"
            >
              {{ row.stocks.length }} {{ declOfNum(row.stocks.length, ['склад', 'склада', 'складов', ]) }}
            </div>
            <div
              v-if="row.expand"
              style="color: #CE1900;cursor: pointer;display: inline; border-bottom: 1px dashed rgba(206, 25, 0, 0.5);"
              @click="changeExpand(row)"
            >
              свернуть
            </div>
            <template v-if="row.stocks.length === 1">
              {{ supplierCodeName(row.supplier) }}
            </template>
          </template>
          <template #deliveryDate="{ row }">
            <div v-if="city && city.cityId && row.stocks.length">
              {{ getSupplierDelivery(row) }}
            </div>
            <el-tooltip
              v-else
              effect="dark"
              content="не указан регион доставки, свяжитесь с менеджером"
            >
              <i class="el-icon-question delivery-question-icon" />
            </el-tooltip>
          </template>
          <template #count="{ row }">
            <template v-if="row.stocks.length === 1">
              {{ row.stocks[0].count }} шт.
            </template>
            <template v-else>
              <span
                :title="'до ' + getRowStocksCount(row.stocks) + ' шт.'"
                class="hide-overflow"
              >до {{ getRowStocksCount(row.stocks) }} шт.
              </span>
            </template>
          </template>
          <template #bay="{ row }">
            <div
              v-if="row.stocks && row.stocks.length === 1"
              style="text-align: center"
            >
              <add-to-cart
                v-model="row.stocks[0].quantity"
                :min="1"
                :max="Number(row.stocks[0].count)"
                :loading="String(loadingIndex) === `${row.article}${row.stocks[0].id}`"
                @addToCart="addSupplierProduct(row, row.stocks[0], 0)"
              />
            </div>
          </template>
          <template #after="{ row, columns, gridTemplateColumns }">
            <template v-if="row.expand">
              <template v-for="(stock, stockIndex) in row.stocks">
                <div
                  :key="`stock---${stockIndex}`"
                  class="after-row"
                  :style="{
                    gridTemplateAreas: `'${columns.reduce((val, currentVal) => val + `${currentVal.prop} `, '')}'`,
                    gridTemplateColumns
                  }"
                >
                  <div
                    v-for="(prop, i) in
                      columns.filter(
                        item => !['price', 'count', 'quantity', 'bay', 'deliveryDate', 'stockName'].includes(item.prop)
                      )"
                    :key="`emptyCol---${i}`"
                    :data-prop="prop.prop"
                  />
                  <div
                    v-if="windowSize.width > 1000"
                    :style="{ gridArea: 'stockName' }"
                  >
                    <template v-if="row.supplier">
                      {{ supplierCodeName(row.supplier) }}
                    </template>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      placement="top-start"
                    >
                      <template #content>
                        <div style="display: flex">
                          <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;width: 100px;">
                            {{ String(stock.name) }}
                          </div>
                          <div>
                            <i
                              class="el-icon-document-copy"
                              style="cursor: pointer"
                              @click="copy(stock.name)"
                            />
                          </div>
                        </div>
                      </template>
                      <div
                        class="cell"
                        style="width: 115px;"
                      >
                        {{ skladId(String(stock.name || '')) }}
                      </div>
                    </el-tooltip>
                  </div>
                  <div
                    class="right"
                    :style="{ gridArea: 'price' }"
                  >
                    <priceOutput :price="normalizePriceMarkup(getPrice(stock))" />
                  </div>
                  <div
                    v-if="windowSize.width > 1200"
                    class="right"
                    :style="{ gridArea: 'deliveryDate' }"
                  >
                    <div v-if="city && city.cityId && row.stocks.length">
                      {{ getStockDelivery(stock) }}
                    </div>
                    <el-tooltip
                      v-else
                      effect="dark"
                      content="не указан регион доставки, свяжитесь с менеджером"
                    >
                      <i class="el-icon-question delivery-question-icon" />
                    </el-tooltip>
                  </div>
                  <div
                    v-if="windowSize.width > 1000"
                    class="right"
                    :style="{ gridArea: 'count' }"
                  >
                    {{ stock.count }} шт.
                  </div>
                  <div :style="{ gridArea: 'bay' }">
                    <add-to-cart
                      v-model="stock.quantity"
                      :min="stock.multiplicity"
                      :step="stock.multiplicity"
                      :max="Number(stock.count - (stock.count % stock.multiplicity))"
                      :loading="String(loadingIndex) === `${row.article}${stock.id}`"
                      @addToCart="addSupplierProduct(row, stock, 0)"
                    />
                  </div>
                </div>
              </template>
            </template>
          </template>
          <template #emptyText="{ section }">
            <template v-if="suppliersLoading">
              Загрузка..
            </template>
            <template v-else>
              <div style="font-weight: bold">
                {{ section.name === 'Аналоги' ? 'Аналоги товара не найдены' : 'Оригинальные запчасти не найдены' }}
              </div>
              <div
                v-if="Object.keys($route.query).length"
                style="font-size: 12px"
              >
                Попробуйте изменить условия фильтров
              </div>
            </template>
          </template>
        </n-block-table>
      </div>

      <basket-aside />

      <settings-dialog v-if="showSettings" />
      <el-dialog
        title="Товар"
        :visible.sync="dialogVisible"
        modal-append-to-body
        :width="!dialogData && !adaptabilityManufacturers.length ? '400px' : undefined"
        destroy-on-close
      >
        <div
          v-loading="dialogLoading"
          class="product-info"
        >
          <div
            class="dialog__container"
          >
            <div class="product-info-tabs">
              <div
                :class="['product-info-tab', {'product-info-tab_active': tab === 'main'}]"
                @click="tab='main'"
              >
                Основная информация
              </div>
              <div
                :class="['product-info-tab', {'product-info-tab_active': tab === 'adaptability'}]"
                @click="tab='adaptability'"
              >
                Применяемость
              </div>
            </div>
            <template v-if="tab === 'main'">
              <div
                class="yandex-images"
                @click="yandexSearch"
              >
                Посмотреть на Яндекс Картинках
              </div>
              <template v-if="dialogData">
                <div
                  v-if="dialogData.images && previewImage"
                  class="part-gallery"
                >
                  <el-image
                    class="part-img part-img-main"
                    :src="`data:image/png;base64,${previewImage.data}`"
                    fit="contain"
                    :preview-src-list="[`data:image/png;base64,${previewImage.data}`]"
                  />
                  <div
                    v-if="dialogData.images && dialogData.images.length > 1"
                    class="part-gallery-previews"
                  >
                    <el-image
                      v-for="image in dialogData.images"
                      :key="image.id"
                      class="part-img"
                      :src="`data:image/png;base64,${image.data}`"
                      fit="contain"
                      @click="previewImage = image"
                    />
                  </div>
                </div>

                <el-image
                  v-if="dialogData.picture"
                  class="part-img part-img-main"
                  :src="dialogData.picture"
                  fit="contain"
                  :preview-src-list="[dialogData.picture]"
                />
                <p>{{ dialogData.description }}</p>
                <p v-if="dialogData.brandName">
                  <strong>Название бренда</strong> {{ dialogData.brandName }}
                </p>
                <p v-if="dialogData.number">
                  <strong>Номер детали</strong> {{ dialogData.number }}
                </p>
                <p v-if="dialogData.addName">
                  <strong>Дополнительное название</strong> {{ dialogData.addName }}
                </p>
                <p v-if="dialogData.genericArticleId">
                  <strong>Идентификатор группы товара</strong> {{ dialogData.genericArticleId }}
                </p>
                <p v-if="dialogData.info">
                  <strong>Иформация о товаре</strong>
                  <el-table
                    :data="dialogData.info"
                    stripe
                    size="medium"
                  >
                    <el-table-column
                      prop="id"
                      width="100"
                      label="Идентификатор информации"
                    />
                    <el-table-column
                      prop="text"
                      label="Текстовое описание"
                    />
                    <el-table-column
                      prop="typeId"
                      label="Идентификатор типа"
                    />
                    <el-table-column
                      prop="typeName"
                      label="Название типа"
                    />
                  </el-table>
                </p>
                <p v-if="dialogData.mainArticle">
                  <strong>текущая деталь является псевдодеталью</strong>
                </p>
                <p v-if="dialogData.oenNumbers">
                  <strong>Оригинальные номера</strong>
                  <el-table
                    :data="dialogData.oenNumbers"
                    stripe
                    size="medium"
                  >
                    <el-table-column
                      prop="blockNumber"
                      width="100"
                      label="Номер блока (порядковый номер)"
                    />
                    <el-table-column
                      prop="brandName"
                      label="Название бренда"
                    />
                    <el-table-column
                      prop="oeNumber"
                      label="Оригинальный OE-номер"
                    />
                    <el-table-column
                      prop="sortNumber"
                      label="Индекс сортировки"
                    />
                  </el-table>
                </p>
                <p v-if="dialogData.eanNumber">
                  <template v-if="Array.isArray(dialogData.eanNumber)">
                    <strong>Штрих-код</strong> {{ dialogData.eanNumber.map(number => number.eanNumber).join(', ') }}
                  </template>
                  <template v-else>
                    <strong>Штрих-код</strong> {{ dialogData.eanNumber }}
                  </template>
                </p>
                <template v-if="dialogData.attributes && dialogData.attributes.length">
                  <el-table
                    :data="dialogData.attributes"
                    stripe
                    size="medium"
                  >
                    <el-table-column
                      prop="name"
                      width="180"
                      label="Характеристика"
                    >
                      <template #default="{row}">
                        <strong>{{ row.name }}</strong>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="value"
                      label="Значение"
                    >
                      <template #default="{row}">
                        {{ row.value }} {{ row.unit }}
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </template>
              <p v-else>
                Нет информации по товару
              </p>
            </template>
            <template v-if="tab === 'adaptability'">
              <template v-if="adaptabilityManufacturers.length">
                <h2>
                  Применяемость
                </h2>
                <div class="adaptability">
                  <div
                    v-for="manufacturer in adaptabilityManufacturers"
                    :key="manufacturer.id"
                    style="display: flex;gap:20px;align-items: flex-start;"
                  >
                    <div
                      class="adaptability__manufacturer"
                      @click="getManufacturerModels(manufacturer.name)"
                    >
                      {{ manufacturer.name }}
                    </div>
                    <div class="adaptability__models">
                      <div v-if="adaptabilityModels[manufacturer.name] === null">
                        Нет информации о моделях
                      </div>
                      <div
                        v-for="model in adaptabilityModels[manufacturer.name]"
                        :key="manufacturer.name + '_' + model"
                      >
                        <div
                          class="adaptability__model"
                          @click="getModelModifications(manufacturer.name, model)"
                        >
                          {{ model }}
                        </div>
                        <div class="adaptability__modifications">
                          <div
                            v-for="modification in adaptabilityModifications[`${manufacturer.name}_${model}`]"
                            :key="`${modification.manufacturerName}_${modification.modelName}`"
                            class="adaptability__modification"
                          >
                            <div class="adaptability__modification">
                              {{ modification.name }}
                            </div>
                            <el-table
                              :data="modificationsAttributes.map(column => {
                                return {
                                  name: column,
                                  value: modification[column]
                                }
                              })"
                              stripe
                              size="medium"
                            >
                              <el-table-column
                                prop="name"
                                width="180"
                                label="Характеристика"
                              >
                                <template #default="{row}">
                                  <strong>{{ modificationsAttributesNames[row.name] }}</strong>
                                </template>
                              </el-table-column>
                              <el-table-column
                                prop="value"
                                label="Значение"
                              />
                            </el-table>
                          </div>
                          <div v-if="adaptabilityModifications[manufacturer.name + model] !== undefined && !adaptabilityModifications[`${manufacturer.name}_${model}`].length">
                            —
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                Нет информации
              </template>
            </template>
          </div>
        </div>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
import copy from 'utils/copy';
import declOfNum from 'utils/declOfNum';
import { getDeliveryDate } from 'utils/deliveryDays';
import { formatDateNumeric } from 'utils/formatDate';
import productsSort from 'utils/productsSort';
import Vue from 'vue';
import {
  mapActions, mapGetters, mapMutations, mapState
} from 'vuex';

import AddToCart from '@/components/add-to-cart/addToCart';
import AppAside from '@/components/app-tree/app-aside';
import BasketAside from '@/components/basket-aside/basketAside';
import SettingsDialog from '@/components/product-table/layout/settingsDialog';
import hide from '@/components/tables/mixins/hide';
import { getPrice, normalizePriceMarkup } from '@/utils/normalizePrice';
import _isEqualSuppliers from '@/views/parts/utils/_isEqualSuppliers';

import supplierCodeName from '../../utils/supplierCodeName';

export default {
  name: 'ViewPart',
  components: {
    AddToCart,
    SettingsDialog,
    BasketAside,
    AppAside
  },
  mixins: [hide],
  metaInfo: {
    title: 'Товары по детали'
  },
  data: () => ({
    activeRowId: null,
    part: {},
    error: false,

    info: {},
    isCollapse: true,

    loading: false,
    loadingIndex: null,

    sort: { prop: 'name', order: 'ASC' },

    dialogLoading: false,
    dialogVisible: false,
    /** @type ISupplierArticleData */
    dialogData: null,
    yandexSearchQuery: null,
    dialogBrand: null,
    dialogDescription: null,
    previewImage: null,
    adaptabilityManufacturers: [],
    adaptabilityModels: {},
    adaptabilityModifications: {},
    modificationsAttributes: [
      'modelName', 'constructionType', 'brakeSystem', 'cylinder', 'cylinderCapacityCcm', 'fuelType',
      'fuelTypeProcess', 'impulsionType', 'motorType', 'motorCodes', 'powerHP', 'powerKW', 'tonnage', 'valves',
    ],
    modificationsAttributesNames: {
      modelName: 'Название модели',
      constructionType: 'форма кузова',
      brakeSystem: 'тормозная система',
      cylinder: 'количество цилиндров',
      cylinderCapacityCcm: 'рабочий обьем в кубических сантиметрах',
      fuelType: 'вид топлива',
      fuelTypeProcess: 'подготовка топлива',
      impulsionType: 'тип привода',
      motorType: 'вид двигателя',
      motorCodes: 'коды двигателей',
      powerHP: 'мощность в л/c',
      powerKW: 'мощность в кВт',
      tonnage: 'тоннаж',
      valves: 'количество клапанов на цилиндр',
    },
    tab: 'main',
  }),
  computed: {
    ...mapState('products', [
      'supplierProducts', 'percentMarkup', 'tableType', 'sortField',
      'sortOrder', 'supplierProductsInfo', 'supplierProductsLoading', 'suppliersNames', 'supplierLoading', 'suppliersLoading'
    ]),
    ...mapState('ui', ['userCurrency', 'basketSize', 'windowSize', 'showSettings']),
    ...mapState('accounts', ['city']),
    ...mapGetters('auth', ['_role', '_roleAdmin']),
    ...mapGetters('categories', ['categories']),
    ...mapState('basket', {
      basketData: 'basket',
    }),
    ...mapState('ui', ['tableVisual']),
    ...mapState('returns', {
      returnBasketId: state => state.basketId
    }),
    mainColumns() {
      /*
      { name: '', prop: 'expandProp', width: '30px', align: 'center', },
      { name: 'Артикул', prop: 'article', width: '150px', sortable: true, show: showColumns.hide, translucent: tableVisual.focus },
      { name: 'Бренд', prop: 'brand', width: '130px', sortable: true, show: showColumns.hide2, translucent: tableVisual.focus },
      { name: 'Название', prop: 'name', width: '1fr', sortable: true },
      { name: 'Поставщик', prop: 'supplier', width: '150px', show: _role === 'admin' },
      { name: 'ID склада', prop: 'guid', width: '150px', show: _role === 'admin' },
      { name: 'Цена', prop: 'price', width: '90px', align: 'right', sortable: true },
      { name: 'Доставка', prop: 'delivery', width: '90px', align: 'right', sortable: true, translucent: tableVisual.focus },
      { name: 'Остаток', prop: 'count', width: '86px', align: 'right', translucent: tableVisual.focus },
      { name: 'Купить', prop: 'bay', width: '122px', align: 'center' },
       */
      if (this.windowSize.width > 1200) return [];
      if (this.windowSize.width > 1000) return [
        'expandProp', 'article', 'brand', 'name', 'supplier',
        'guid', 'stockName', 'price', 'count', 'bay'
      ];
      if (this.windowSize.width > 800) return ['expandProp', 'article', 'brand', 'name', 'price', 'bay'];
      if (this.windowSize.width > 620) return ['expandProp', 'article', 'name', 'price', 'bay'];
      return ['expandProp', 'article', 'name', 'price'];
    },
    sortedProducts() {
      let products = structuredClone(this.supplierProducts.products);
      /* eslint-disable vue/no-side-effects-in-computed-properties */
      products = products.sort((a) => (a.supplier === 'Николь' ? -1 : 0));

      const brandFilter = this.$route.query.brand || [];
      if (brandFilter.length) {
        products = products.filter(product => brandFilter.includes(product.brand));
      }

      const priceFilter = (this.$route.query.price || '').split('-');
      if (priceFilter.length === 2) {
        products = products.map((product) => {
          product.stocks = product.stocks
            .filter(stockProduct => stockProduct[this.userCurrency !== 'RU' ? 'tengePrice' : 'price'] >= priceFilter[0])
            .filter(stockProduct => stockProduct[this.userCurrency !== 'RU' ? 'tengePrice' : 'price'] <= priceFilter[1]);
          return product;
        });
      }

      products = products.filter(product => product.stocks.length > 0);

      return products;
    },
    sortedAnalogs() {
      let analogs = structuredClone(this.supplierProducts.analog);
      /* eslint-disable vue/no-side-effects-in-computed-properties */
      analogs = analogs.sort((a) => (a.supplier === 'Николь' ? -1 : 0));

      const brandFilter = this.$route.query.brand || [];
      if (brandFilter.length) {
        analogs = analogs.filter(product => brandFilter.includes(product.brand));
      }

      const priceFilter = (this.$route.query.price || '').split('-');
      if (priceFilter.length === 2) {
        analogs = analogs.map((product) => {
          product.stocks = product.stocks
            .filter(stockProduct => stockProduct[this.userCurrency !== 'RU' ? 'tengePrice' : 'price'] >= priceFilter[0])
            .filter(stockProduct => stockProduct[this.userCurrency !== 'RU' ? 'tengePrice' : 'price'] <= priceFilter[1]);
          return product;
        });
      }

      analogs = analogs.filter(product => product.stocks.length > 0);

      return analogs;
    },
  },
  watch: {
    async $route() {
      const { brand, article } = this.$route.params;
      if (this.supplierProductsInfo.brand !== brand || this.supplierProductsInfo.article !== article) {
        await this.searchDetail({ brand, article });
      }
    },
    dialogVisible(value) {
      const { brand, article } = this.$route.params;
      if (!value) return;
      this.adaptabilityManufacturers = [];
      this.adaptabilityModels = {};
      this.adaptabilityModifications = {};
      this.getAdaptabilityManufacturers(
        {
          article,
          brand,
        }
      ).then((response) => {
        console.log(response);
        this.adaptabilityManufacturers = response;
      }).catch((err) => { this.$alert(err.message || err, 'Не удалось получить данные о применяемости'); });
    }
  },
  async mounted() {
    const { brand, article } = this.$route.params;
    if (this.supplierProductsInfo.brand !== brand || this.supplierProductsInfo.article !== article) {
      await this.searchDetail({ brand, article });
    }
  },
  methods: {
    supplierCodeName,
    ...mapActions('products', ['GetSupplierProducts', 'GetSuppliersNames', 'GetProductInfo']),
    ...mapMutations('products', ['CLEAR_SUPPLIER_PRODUCTS', 'SET_SUPPLIERS_LOADING']),
    ...mapActions('tecdoc', ['GetArticleByBrandAndNumber', 'getAdaptabilityManufacturers', 'getAdaptabilityModels', 'getAdaptabilityModifications']),
    ...mapActions('returns', ['AddItemToReturnProductsBasket', 'basketHasProductId']),
    getPrice,
    declOfNum,
    async searchDetail({ brand, article }) {
      if (this.supplierProductsLoading) {
        return;
      }

      await this.SET_SUPPLIERS_LOADING(true);
      await this.GetSuppliersNames();
      this.CLEAR_SUPPLIER_PRODUCTS();
      const promises = [];
      this.suppliersNames.forEach((supplierName) => {
        promises.push(
          this.GetSupplierProducts({
            brand,
            article,
            tableType: 'part',
            supplierName
          })
            .then(() => {
              this.error = false;
            })
            .catch(() => {
              this.error = true;
            })
        );
      });
      Promise.all(promises).finally(() => {
        this.SET_SUPPLIERS_LOADING(false);
      });
    },
    arrayMin: arr => normalizePriceMarkup(arr.reduce((acc, p) => Math.min(acc, getPrice(p)), Infinity)),
    skladId: value => value.slice(0, 6),

    changeExpand(row) {
      if (row.stocks.length <= 1) return;
      const find = item => item.article === row.article && item.guid === row.guid && item.name === row.name;
      const val = row.expand !== undefined ? !row.expand : true;
      let index = this.supplierProducts.products.findIndex(find);
      if (index > -1) {
        this.$set(this.supplierProducts.products[index], 'expand', val);
      } else {
        index = this.supplierProducts.analog.findIndex(find);
        this.$set(this.supplierProducts.analog[index], 'expand', val);
      }
    },

    rowClassName(row, index) {
      return {
        even: this.tableVisual.striped && index % 2 === 0
      };
    },

    async addSupplierProduct(product, stock, index) {
      const supplier = { ...product };
      supplier.stocks = stock;

      if (supplier.stocks.quantity === 0) return;

      if (product.stocks.length === 1) {
        this.loadingIndex = `${product.article}${product.stocks[0].id}`;
      } else {
        this.loadingIndex = product.stocks[index].id;
      }

      const basketThisProduct = this.basketData.find((prod) => {
        if (prod.supplier === null) return false;

        const basketSupplier = JSON.parse(prod.supplier);
        return _isEqualSuppliers(supplier, basketSupplier);
      });

      if (this.returnBasketId) {
        if (await this.basketHasProductId(product.id || product)) {
          this.$confirm(`Товар "${product.name}" уже есть в корзине, хотите добавить еще один?`, 'Добавление', {
            confirmButtonText: 'Да',
            cancelButtonText: 'Отмена',
            type: 'warning'
          }).then(async () => {
            await this.addReturnProduct(product.id || product, product.quantity);
          }).catch(() => {
            //
          });
        } else {
          await this.addReturnProduct(product.id || product, product.quantity);
        }
        return;
      }

      try {
        if (basketThisProduct === undefined) {
          if (stock.quantity > 0) {
            await this.$store.dispatch('basket/PostProductInBasket', {
              quantity: supplier.stocks.quantity,
              supplier,
            });
          }
        } else {
          await this.$store.dispatch('basket/PutChangeQuantityProduct', {
            basketItemId: basketThisProduct.id,
            quantity: basketThisProduct.quantity + supplier.stocks.quantity,
            supplier
          });
        }
      } catch (message) {
        this.handleAddProductErrors(message);
      }

      product.stocks[index].quantity = product.stocks[index].multiplicity;
      this.loadingIndex = NaN;
    },
    async addReturnProduct(productId, quantity) {
      return this.$prompt('Укажите причину возврата товара', 'Возврат', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Отмена',
        inputPlaceholder: 'Причина возврата',
        inputValidator: value => value.length >= 3 && value.length <= 30,
        inputErrorMessage: 'Комментарий должен содержать от 3 до 20 символов',
      })
        .then(async ({ value }) => {
          await this.AddItemToReturnProductsBasket({
            productId,
            quantity,
            returnProductsBasketId: this.returnBasketId,
            reason: value
          })
            .catch(({ message }) => {
              this.handleAddProductErrors(message);
            });
        });
    },
    getRowStocksCount(stocks) {
      return stocks.reduce(
        (value, currentValue) => value
          + (typeof currentValue.count === 'string'
            ? parseInt(currentValue.count.replace(/\D/g, ''), 10)
            : currentValue.count
          ),
        0
      );
    },
    handleAddProductErrors(errorMessage) {
      this.$alert(errorMessage, 'Добавление в корзину', {
        confirmButtonText: 'OK',
      });
    },
    productsSort(event) {
      const { products, analog } = this.supplierProducts;
      this.supplierProducts.products = productsSort(products, event, 'part');
      this.supplierProducts.analog = productsSort(analog, event, 'part');

      this.sort = event;
      this.activeRowId = null;
    },
    async showInfoProduct(row) {
      this.dialogLoading = true;
      this.dialogVisible = true;
      this.dialogData = null;
      this.tab = 'main';
      this.yandexSearchQuery = `${row.brand} ${row.name} ${row.article}`;

      if (row.supplier === 'Николь') {
        this.dialogLoading = true;
        await this.GetProductInfo(row.guid)
          .then((response) => {
            console.log(response);
            this.dialogData = {
              brandId: null,
              brandName: response.brand,
              number: '',
              description: response.description,
              documents: null,
              attributes: [],
              picture: `${import.meta.env.VUE_APP_IMG_URL + response.externalId}.JPEG`
            };
          }).catch(() => {
            this.dialogData = null;
          }).finally(() => {
            this.dialogLoading = false;
          });
        return;
      }

      await this.GetArticleByBrandAndNumber({ brand: row.brand, article: row.article })
        .then((response) => {
          this.dialogData = response;
          this.previewImage = response?.images[0] || null;
        }).catch(() => {
          this.dialogData = null;
        }).finally(() => {
          this.dialogLoading = false;
        });
    },
    getSupplierDelivery(row) {
      const minDeliveryDays = row.stocks.reduce((acc, stock) => {
        if ((stock?.deliveryDays || stock?.delivery) === null) {
          return null;
        }
        return Math.min(acc, stock.deliveryDays || stock.delivery);
      }, (row.stocks[0]?.deliveryDays || row.stocks[0]?.delivery));

      if (minDeliveryDays === null || Number.isNaN(minDeliveryDays)) {
        return null;
      }
      const deliveryDate = getDeliveryDate({
        deliveryDays: minDeliveryDays
      })?.deliveryDate;
      if (!deliveryDate) {
        return null;
      }
      return formatDateNumeric(deliveryDate);
    },
    getStockDelivery(stock) {
      if ((typeof stock.deliveryDays === 'undefined' || stock.deliveryDays === null) && !stock.delivery) {
        return null;
      }
      const deliveryDate = getDeliveryDate({
        deliveryDays: (stock.deliveryDays || stock.delivery)
      })?.deliveryDate;
      if (!deliveryDate) {
        return null;
      }
      return formatDateNumeric(deliveryDate);
    },
    getManufacturerModels(manufacturerName) {
      const { brand, article } = this.$route.params;
      this.getAdaptabilityModels(
        {
          article,
          brand,
          manufacturerName
        }
      ).then((response) => {
        Vue.set(this.adaptabilityModels, manufacturerName, response || null);
      }).catch((err) => {
        this.$alert(err.message || err, 'Не удалось получить данные о применяемости для производителя');
      });
    },
    getModelModifications(manufacturerName, modelName) {
      const { brand, article } = this.$route.params;
      this.getAdaptabilityModifications(
        {
          article,
          brand,
          manufacturerName,
          modelName
        }
      ).then((response) => {
        Vue.set(this.adaptabilityModifications, `${manufacturerName}_${modelName}`, response);
      }).catch((err) => {
        this.$alert(err.message || err, 'Не удалось получить данные о применяемости для модели');
      });
    },
    yandexSearch() {
      window.open(`https://yandex.ru/images/search?text=${encodeURI(this.yandexSearchQuery)}`);
    },
    copy,
    normalizePriceMarkup
  }
};
</script>

<style lang="sass">
.part-table
  .even.n-table-row > *
    background: #fafafa

.part-gallery
  display: grid
  grid-template-columns: 1fr 100px
  grid-column-gap: 20px
  grid-row-gap: 20px
  align-items: start
.part-gallery-previews
  display: flex
  flex-direction: column
  gap: 20px
  max-height: 400px
  overflow-y: auto
  padding-right: 15px
.part-img
  display: block
  cursor: pointer
  border: 2px solid #1C6A9E
  flex-shrink: 0
  img
    max-height: 300px
  &:hover
    border-color: #5eade1
  &:nth-child(1)
    grid-area: 1 / 1 / 5 / 2
  &:nth-child(2)
    grid-area: 1 / 2 / 2 / 3
  &:nth-child(3)
    grid-area: 2 / 2 / 3 / 3
  &:nth-child(4)
    grid-area: 3 / 2 / 4 / 3
  &:nth-child(5)
    grid-area: 4 / 2 / 5 / 3
.part-img-main
  height: 400px
.part-img-main img
  height: 400px
  max-height: 400px
.adaptability
  margin: 20px 0
  display: flex
  flex-direction: column
  gap: 20px
  &__manufacturer
    cursor: pointer
    padding: 5px
    width: 200px
    color: #7b8896
    font-weight: bold
    font-size: 30px
    line-height: 1
    word-wrap: break-word
    &:hover
      background-color: #def0ff
  &__models
    margin-left: 15px
  &__model
    cursor: pointer
    padding: 5px
    width: 200px
    &:hover
      background-color: #def0ff
  &__modifications
    margin-left: 25px
  &__modification
    cursor: pointer
    padding: 10px 5px
.yandex-images
  box-sizing: border-box
  margin-right: 10px
  padding: 10px
  border: 1px solid #1f75a8
  border-radius: 3px
  font-size: 14px
  line-height: 18px
  cursor: pointer
  width: 200px
  background-color: #ecf5ff
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' fill='%231f75a8' viewBox='-2 -4 24 24' opacity='0.3'%3E%3Cpath d='M18 8.503V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h3.504l4.39-7.322a3 3 0 0 1 4.69-.582L18 8.503zm0 2.823-3.828-3.814a1 1 0 0 0-1.563.195L8.836 14H17a1 1 0 0 0 1-1v-1.674zM3 0h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm3 9a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z'/%3E%3C/svg%3E")
  background-size: 70px
  background-position: right -15px bottom -20px
  background-repeat: no-repeat
  margin-bottom: 20px
  &:hover
    background-color: #def0ff

.product-info-tabs
  display: flex
  gap: 20px
  font-size: 14px
  margin-bottom: 25px
  border-bottom: 1px solid #ccc
.product-info-tab
  border-bottom: 2px solid transparent
  cursor: pointer
  margin-bottom: -1px
  padding: 6px 6px
  &:hover:not(&_active)
    opacity: .8
  &_active
    border-bottom: 2px solid #1C6A9E
</style>

<style scoped lang="sass">
.part-page
  &__wrapper
    display: flex
    flex-flow: column
    height: calc(100vh - 42px)
    max-height: calc(100vh - 42px)
  &__body
    padding: 10px
    flex: 1
  &__header
    display: flex
    flex-flow: nowrap
    align-items: center
    justify-content: space-between
    margin-bottom: 7px
    margin-top: 10px
    @media (max-width: 600px)
      flex-direction: column
      align-items: flex-start
    h2
      font-size: 32px
      color: #000
      margin: 0
.expand-button
  cursor: pointer
.expand-link
  cursor: pointer
  color: rgb(28, 106, 158)
  text-decoration: underline
.part-table
  height: calc(100% - 50px)

.name-row
  flex: 1
  cursor: pointer

.after-row
  display: grid
  grid-column: 1 / -1
  border-top: 1px solid #ebeef5
  &:last-child
    border-bottom: 1px solid #ebeef5
  > div
    display: flex
    align-items: center
    justify-content: center
    padding: 5px 7px
    font-size: 11px
    color: #1c1c1c
    background: white
    transition: .2s
    &:not(:first-child)
      border-left: 1px solid #ebeef5
  > div.right
    justify-content: flex-end
  &:hover > div
    background: #def0ff
.hide-overflow
  overflow: hidden
  text-overflow: ellipsis
.delivery-question-icon
  color: #1C6A9E
  font-size: 14px
.loading-indicator
  background: rgb(252, 248, 237)
  color: rgb(191, 140, 0)
  display: inline-flex
  font-size: 12px
  border-radius: 2px
  padding: 5px
  margin: 0 10px
</style>
