import axiosInstance from '../../api/axiosInstance';

const state = () => ({
  /** @type IRecommendationItem[] */
  recommendations: [],
  loading: false,
});

const mutations = {
  SET_RECOMMENDATIONS(state, recommendations) {
    state.recommendations = recommendations
  },
  SET_LOADING(state, loadingState) {
    state.loading = loadingState
  },
};

const actions = {
  getRecommendations({ commit, rootState }) {
    const basketIds = rootState.basket.multiBasket.map(item => item.id)
    commit('SET_RECOMMENDATIONS', []);
    commit('SET_LOADING', true);
    return axiosInstance({
      url: '/api/recommendations',
      params: {
        basketIds
      }
    })
      .then((recommendations) => {
        commit(
          'SET_RECOMMENDATIONS',
          recommendations.map(recommendation => {
            return {
              ...recommendation,
              quantity: recommendation.quantity || 1
            }
          })
        );
        return recommendations;
      }).finally(() => {
        commit('SET_LOADING', false);
      })
  },
};


const getters = {
  recommendations: state => state.recommendations,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
