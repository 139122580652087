<template>
  <el-dialog
    class="settings"
    title="Настройки"
    :width="windowSize.width > 830 ? '800px' : 'calc(100% - 30px)'"
    :lock-scroll="false"
    :visible="true"
    append-to-body
    @close="SET_SHOW_SETTINGS(false)"
  >
    <h4 class="settings__title">
      Регион доставки
    </h4>
    <div class="settings__params">
      <div class="settings__item">
        <el-select
          v-model="selectedCity"
          placeholder="Выберите регион"
          :loading="citiesLoading"
          value-key="cityId"
        >
          <el-option
            v-for="city in cities"
            :key="'city_' + city.cityId"
            :label="city.name"
            :value="city"
          />
        </el-select>
      </div>
    </div>
    <h4 class="settings__title">
      Колонки отображаемые на странице товаров
    </h4>
    <div class="settings__params">
      <el-checkbox
        v-model="showColumns.hide"
        class="settings__item"
        @change="changeShowColumns('hide')"
      >
        Артикул
      </el-checkbox>
      <el-checkbox
        v-model="showColumns.hide2"
        class="settings__item"
        @change="changeShowColumns('hide2')"
      >
        Бренд
      </el-checkbox>
      <el-checkbox
        v-model="showColumns.hide3"
        class="settings__item"
        @change="changeShowColumns('hide3')"
      >
        Код 1С
      </el-checkbox>
      <el-checkbox
        v-model="showColumns.hide4"
        class="settings__item"
        @change="changeShowColumns('hide4')"
      >
        Кратность
      </el-checkbox>
      <el-checkbox
        v-model="showColumns.hide5"
        class="settings__item"
        @change="changeShowColumns('hide5')"
      >
        ОЕМ
      </el-checkbox>
    </div>
    <h4 class="settings__title">
      Процент вашей наценки
    </h4>
    <div class="settings__params">
      <div class="settings__item settings__item_percent">
        <el-input
          v-model="percent"
          class="settings__item_percent__input"
          size="small"
        >
          <template #suffix>
            <i class="input-percent">%</i>
          </template>
        </el-input>
        <div class="settings__item_percent__text">
          Цены в каталоге будут отображаться с учётом указанной наценки
        </div>
      </div>
    </div>
    <h4 class="settings__title">
      Внешний вид
    </h4>
    <div class="settings__params">
      <el-checkbox
        v-model="tableVisualSettings.striped"
        class="settings__item"
        @change="changeVisualTableSettings('striped', $event)"
      >
        Показывать чередующиеся строки
      </el-checkbox>
      <el-checkbox
        v-model="tableVisualSettings.focus"
        class="settings__item"
        @change="changeVisualTableSettings('focus', $event)"
      >
        Фокус на важных колонках
      </el-checkbox>
    </div>
    <h4 class="settings__title">
      Валюта каталога
    </h4>
    <div class="settings__params settings__params_price">
      <el-radio
        v-model="price"
        label="RU"
        class="settings__item"
      >
        Рубли
      </el-radio>
      <el-radio
        v-model="price"
        label="KZ"
        class="settings__item"
      >
        Тенге
      </el-radio>
      <el-radio
        v-model="price"
        label="KZ_RU"
        class="settings__item"
      >
        Тенге + рубли
        <span class="settings__price-text">
          Цены в тенге, в корзине — обе валюты
        </span>
      </el-radio>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions,mapMutations, mapState } from 'vuex';

import hide from '@/components/tables/mixins/hide';
import { currencyMask } from '@/utils/inputMasks';

export default {
  name: 'SettingsDialog',
  mixins: [hide],
  data() {
    return {
      citiesLoading: false,
    };
  },
  computed: {
    ...mapState('ui', ['tableVisual', 'userCurrency', 'multipleCurrencies', 'windowSize']),
    ...mapState('products', ['percentMarkup']),
    ...mapState('accounts', ['city', 'cities']),
    percent: {
      set(val) {
        this.SET_PERCENT_MARKUP(currencyMask(val));
      },
      get() {
        return this.percentMarkup.replace('.', ',');
      }
    },
    price: {
      set(val) {
        let currency = val;
        this.SET_MULTIPLE_CURRENCIES(val === 'KZ_RU');
        if (val === 'KZ_RU') {
          currency = 'KZ';
        }
        this.SET_USER_CURRENCY(currency);
      },
      get() {
        return this.multipleCurrencies ? 'KZ_RU' : this.userCurrency;
      }
    },
    selectedCity: {
      /**
       * @param {City} city
       * @return {Promise<void>}
       */
      async set(city) {
        await this.SetCity(city.cityId);
        await this.SET_CITY(city);
        this.$router.go();
      },
      get() {
        return this.city;
      }
    },
    tableVisualSettings() {
      return this.tableVisual;
    }
  },
  created() {
    this.citiesLoading = true;
    this.GetCities().finally(() => {
      this.citiesLoading = false;
    });
  },
  methods: {
    ...mapActions('accounts', ['SetCity', 'GetCities']),
    ...mapMutations('ui', [
      'SET_SHOW_COLUMNS', 'SET_SHOW_SETTINGS', 'SET_TABLE_VISUAL_SETTINGS', 'SET_USER_CURRENCY', 'SET_MULTIPLE_CURRENCIES'
    ]),
    ...mapMutations('products', ['SET_PERCENT_MARKUP']),
    ...mapMutations('accounts', ['SET_CITY']),

    changeShowColumns(el) {
      this.SET_SHOW_COLUMNS({
        [el]: this.showColumns[el]
      });
    },

    changeVisualTableSettings(el, e) {
      this.SET_TABLE_VISUAL_SETTINGS({
        [el]: e
      });
    }
  }
};
</script>

<style scoped lang="sass">
.settings
  &__title
    padding: 5px 10px
    margin: 0
    &:first-child
      padding-top: 0
  &__params
    display: flex
    flex-direction: column
    align-items: flex-start
    margin-bottom: 5px
    &_price
      margin-top: 2px
      .settings__item
        padding: 5px 10px
  &__item
    padding: 7px 10px
    &_percent
      &__input
        width: 100px
        margin-right: 15px
      &__text
        display: block
        font-size: .8em
        margin-top: 6px
  &__price-text
    font-size: .8em
    margin-top: 6px
    margin-left: 5px
    @media (max-width: 700px)
      display: block
      margin-left: 27px

.input-percent
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  font-style: normal
  margin-right: 15px
</style>
