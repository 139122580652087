<template>
  <div :class="b({ basketSize })">
    <div style="display: flex">
      <change-basket-buttons
        id="v-step-5"
        :basket-size="basketSize"
      />
      <div
        v-if="windowSize.width > 900"
        class="resize-buttons v-step-45"
      >
        <div
          v-if="basketSize !== 'big'"
          class="resize-buttons__button"
          @click="CHANGE_BASKET_SIZE(windowSize.width > 900 ? 'big' : basketSize === 'big' ? 'small' : 'big')"
        >
          На весь экран
        </div>
        <div
          v-if="windowSize.width > 900 && basketSize !== 'default'"
          class="resize-buttons__button"
          @click="CHANGE_BASKET_SIZE('default')"
        >
          Развернуть
        </div>
        <div
          v-if="basketSize !== 'small'"
          class="resize-buttons__button"
          @click="CHANGE_BASKET_SIZE(windowSize.width > 900 ? 'small' : basketSize === 'big' ? 'small' : 'big')"
        >
          Свернуть
        </div>
      </div>
    </div>

    <hr>
    <div
      style="margin-bottom: auto; transition: .2s; overflow: hidden"
      :style="tableWrapperStyle"
    >
      <n-table
        v-if="windowSize.width > 800"
        ref="table"
        v-model="selectItem"
        v-loading="basketLoading"
        :data="basketProducts"
        :default-sort="sort"
        :style="{ height: '100%' }"
        :columns="[
          { name: '', prop: 'index', width: '35px', align: 'center', translucent: true },
          { name: 'Артикул', prop: 'article', width: '115px', sortable: true },
          { name: 'Название', prop: 'name', width: 'minmax(100px, 1fr)', sortable: true },
          { name: 'К.', prop: 'minShippingRate', width: '38px', align: 'center', show: !returnBasketId },
          { name: 'Склад', prop: 'storage', width: '50px', align: 'center', show: !returnBasketId },
          { name: '', prop: 'rowButtons', width: '1fr', align: 'center', show: windowSize.width <= 700 },
          { name: 'Цена', prop: 'price', width: '76px', align: 'right', sortable: true, show: !returnBasketId },
          {
            name: 'Поставщик',
            prop: 'supplier',
            width: '105px',
            align: 'center',
            sortable: true,
            show: _role === 'admin' || _role === 'manager'
          },
          { name: 'Доставка', prop: 'delivery', width: '100px', align: 'center', sortable: true, show: !returnBasketId },
          { name: 'Причина возврата', prop: 'reason', width: '120px', align: 'right', show: returnBasketId > 0 },
          { name: 'Кол-во', prop: 'quantity', width: '93px', align: 'center' },
          { name: 'Сумма', prop: 'sum', width: '76px', align: 'right' },
          { name: 'Убрать', prop: 'del', width: '56px', align: 'center', hideTitleOnMobile: true },
        ]"
        :main-columns="mainColumns"
        :row-class="tableRowClassName"
        :empty-text="returnBasketId ? 'Добавьте товар в корзину, для дальнейшего оформления возврата' :'Пусто'"
        size="small"
        row-index-key="id"
        @sort-change="sort = $event"
      >
        <template #name="scope">
          <template v-if="scope.row.supplier">
            {{ parseSupplier(scope.row.supplier).name }}
          </template>
          <template v-else>
            {{ scope.row.name }}
          </template>
          <product-row-buttons
            v-if="windowSize.width > 700"
            :row="scope.row"
            :basket-id="activeBasketId"
          />
        </template>
        <template #storage="{row}">
          <template v-if="row.supplier">
            {{ supplierCodeName(JSON.parse(row.supplier).supplier) }}
          </template>
        </template>
        <template #rowButtons="scope">
          <product-row-buttons
            :row="scope.row"
            :basket-id="activeBasketId"
            visible
          />
        </template>
        <template
          v-once
          #article="scope"
        >
          <template v-if="scope.row.supplier">
            <el-tooltip
              v-if="scope.row.supplier && parseSupplier(scope.row.supplier).article.length >= 14"
              effect="dark"
              :content="parseSupplier(scope.row.supplier).article"
              placement="top"
            >
              <span style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis">{{ parseSupplier(scope.row.supplier).article }}</span>
            </el-tooltip>
            <template v-else>
              {{ parseSupplier(scope.row.supplier).article }}
            </template>
          </template>
          <el-tooltip
            v-else-if="scope.row.article && scope.row.article.length >= 14"
            effect="dark"
            :content="scope.row.article"
            placement="top"
          >
            <span style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis">{{ scope.row.article }}</span>
          </el-tooltip>
          <template v-else>
            {{ scope.row.article }}
          </template>
        </template>
        <template #index="prop">
          {{ prop.index + 1 }}
        </template>
        <template #headminShippingRate>
          <el-popover
            placement="top"
            trigger="hover"
            content="Кратность"
            popper-class="custom-popover"
          >
            <p class="custom-popover-text">
              Кратность
            </p>
            <template #reference>
              <span>К.</span>
            </template>
          </el-popover>
        </template>
        <template #warehouseId="scope">
          <div v-if="scope.row.warehouseId === 1">
            Омск
          </div>
          <div v-if="scope.row.warehouseId === 2">
            Новосибирск
          </div>
        </template>
        <template #price="scope">
          <priceOutput :price="normalizePriceMarkup(getPrice(scope.row))" />
        </template>
        <template #supplier="scope">
          <div>
            {{ supplierFilter(scope.row.supplier) }}
          </div>
        </template>
        <template #deliveryHead>
          Доставка
          <el-tooltip
            v-if="shipmentDescription"
            effect="dark"
            :content="shipmentDescription"
          >
            <i class="el-icon el-icon-info" />
          </el-tooltip>
        </template>
        <template #delivery="scope">
          <div v-if="city && city.cityId">
            {{ supplierDelivery(scope.row) }}
          </div>
          <el-tooltip
            v-else
            effect="dark"
            content="не указан регион доставки, свяжитесь с менеджером"
          >
            <i class="el-icon-question delivery-question-icon" />
          </el-tooltip>
        </template>
        <template #reason="scope">
          <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis">
            {{ scope.row.reason }}
          </div>
        </template>
        <template #quantity="scope">
          <n-input-number
            v-model="scope.row.quantity"
            :min="calcStep(scope.row)"
            :max="calcMax(scope.row)"
            :step="calcStep(scope.row)"
            @change="inputPutQuantity(scope.row.id, $event, scope.row)"
          />
        </template>
        <template #sum="scope">
          <priceOutput :price="normalizePriceMarkup(getPrice(scope.row) * scope.row.quantity)" />
        </template>
        <template #del="scope">
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            @click="deleteProduct(scope.row)"
          />
        </template>
      </n-table>
      <div
        v-else
        style="overflow-y: auto;height: 100%"
      >
        <div
          v-for="(item, i) in basketProducts"
          :key="`product---${i}`"
          class="small-product"
        >
          <h2>
            {{ item.name }}
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="deleteProduct(item)"
            />
          </h2>
          <div class="small-product__info">
            <div>
              <h3>Артикул</h3>
              <p>{{ item.article }}</p>
            </div>
            <div>
              <h3>Цена</h3>
              <p><priceOutput :price="normalizePrice(getPrice(item))" /></p>
            </div>
            <div>
              <n-input-number
                v-model="item.quantity"
                :min="calcStep(item)"
                :max="calcMax(item)"
                :step="calcStep(item)"
                @change="inputPutQuantity(item.id, $event, item)"
              />
            </div>
            <div>
              <h3>Сумма</h3>
              <p><priceOutput :price="normalizePrice(item.quantity * getPrice(item))" /></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="basket__footer basket__footer_position-bottom"
      :class="{'basket__footer_return': returnBasketId}"
    >
      <div class="basket__footer__text-wrap">
        <template v-if="windowSize.width > 780">
          <p class="basket__footer-text font-bg">
            Количество: {{ quantityBasket }}
          </p>
          <p
            v-if="+percentMarkup && !returnBasketId"
            class="basket__footer-text font-bg"
          >
            Сумма с наценкой: <price-output :price="normalizePriceMarkup(+sumBasket)" />
          </p>
          <p
            v-if="!+percentMarkup"
            class="basket__footer-text font-bg"
          >
            Сумма: <price-output :price="normalizePrice(+sumBasket)" />
          </p>
          <p
            v-if="!returnBasketId"
            class="basket__footer-text font-bg"
          >
            Общее количество: {{ commonQuantity }}
          </p>
          <p
            v-if="+percentMarkup && !returnBasketId"
            class="basket__footer-text font-bg"
          >
            Общая сумма с наценкой:
            <price-output :price="normalizePriceMarkup(+commonSum)" />
          </p>
        </template>
        <div
          v-if="!+percentMarkup && !returnBasketId"
          class="basket__footer-text font-bg"
        >
          Общая сумма:
          <price-output :price="normalizePrice(+commonSum)" />
          <span v-if="commonSum < minimumOrderSum">
            <el-popover
              placement="top"
              trigger="hover"
              width="340"
              :content="`Сумма заказа должна быть не менее ${minimumOrderSum} ${userCurrencySign}`"
            >
              <template #reference>
                <i
                  class="el-icon-warning"
                  style="color: #e6a23c"
                />
              </template>
            </el-popover>
          </span>
        </div>
      </div>

      <div
        v-if="priceUpdate.visible"
        class="el-popover el-popper basket-popover"
        :class="{ 'basket-popover_animation': notifyBzzz }"
        style="width: 600px; position: fixed; top: 50px; right: 10px; z-index: 2001;"
      >
        <div class="basket-popover__title">
          Изменение цены на товары
          <i
            class="el-icon-close"
            style="cursor: pointer"
            @click="RESET_PRICE_UPDATE"
          />
        </div>
        <div data-v-5e4a770c="">
          <div v-if="!priceUpdate.error">
            <div class="basket__footer-popover__row basket__footer-popover__row_head">
              <span class="basket__footer-popover__product-name">Название</span>
              <span class="basket__footer-popover__currency">Старая цена</span>
              <span class="basket__footer-popover__currency">Новая цена</span>
            </div>
            <div
              v-for="(priceItem, i) in priceUpdate.prices"
              :key="`priceItem---${i}`"
              class="basket__footer-popover__row"
            >
              <span class="basket__footer-popover__product-name">{{ priceItem.productName }}</span>
              <span class="basket__footer-popover__currency"><price-output :price="normalizePrice(priceItem.oldPrice)" /></span>
              <span class="basket__footer-popover__currency"><price-output :price="normalizePrice(priceItem.newPrice)" /></span>
            </div>
          </div>
          <div v-else>
            {{ priceUpdate.error }}
          </div>
        </div>
      </div>

      <el-button
        v-if="!returnBasketId && !returnsTour"
        class="v-step-8"
        :type="quantityBasket !== 0 ? 'primary' : 'info'"
        :disabled="quantityBasket === 0"
        plain
        size="small"
        icon="el-icon-s-order"
        @click="goToOrder()"
      >
        Перейти к заказу
      </el-button>
      <el-button
        v-else
        :type="quantityBasket !== 0 ? 'primary' : 'info'"
        :disabled="quantityBasket === 0"
        plain
        size="small"
        class="v-step-return-button"
        icon="el-icon-s-order"
        @click="goToReturn()"
      >
        Перейти к возврату
      </el-button>
    </div>
  </div>
</template>

<script>
import debounce from 'utils/debounce';
import { getDeliveryDate, getDeliveryHint } from 'utils/deliveryDays';
import { formatDateNumeric } from 'utils/formatDate';
import { getPrice,normalizePrice, normalizePriceMarkup } from 'utils/normalizePrice';
import ymMixin from 'utils/ymMixin';
import {
  mapActions, mapGetters, mapMutations, mapState
} from 'vuex';

import ChangeBasketButtons from '@/components/basket-aside/subcomponents/changeBasketButtons';
import ProductRowButtons from '@/components/ProductRowButtons';
import productsSort from '@/utils/productsSort';

import supplierCodeName from "../../../utils/supplierCodeName";

export default {
  name: 'BasketTable',
  components: {
    ProductRowButtons,
    ChangeBasketButtons,
  },
  mixins: [ymMixin],
  props: {
    basketSize: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      selectItem: 0,
      currentTab: '',
      min: 3,
      max: 30,
      notifyBzzz: false,
      sort: { prop: 'name', order: 'ASC' },
      dialogData: null,
      dialogVisible: false,
      returnsTour: false,
    };
  },
  computed: {
    ...mapState('basket', [
      'activeBasketId',
      'multiBasket',
      'basket',
      'priceUpdate',
      'deliveryDays',
      'regionalDeliveryDays',
      'basketLoading'
    ]),
    ...mapState('ui', ['userCurrency', 'windowSize']),
    ...mapState('products', ['tourStatus', 'percentMarkup']),
    ...mapState('accounts', ['city']),
    ...mapState('returns', {
      returnBasketId: state => state.basketId,
      returnBasketItems: state => state.basketItems,
    }),
    ...mapGetters('auth', ['_role', '_warehouseId']),
    ...mapGetters('products', ['minimumOrderSum']),
    ...mapGetters('ui', ['userCurrencySign', 'userCurrencySign']),
    tableWrapperStyle() {
      const size = {
        small: '0',
        default: '150px',
        big: '100vh'
      };
      return {
        height: size[this.basketSize],
        margin: this.basketSize === 'small' ? '0 10px' : '0 10px 10px'
      };
    },
    sumBasket() {
      if (!this.returnBasketId) {
        return this.basket.reduce((sum, current) => sum + getPrice(current) * current.quantity, 0);
      }
      return this.returnBasketItems.reduce((sum, current) => sum + getPrice(current) * current.quantity, 0);
    },
    quantityBasket() {
      if (!this.returnBasketId) {
        return this.basket.reduce((sum, current) => sum + current.quantity, 0);
      }
      return this.returnBasketItems.reduce((sum, current) => sum + current.quantity, 0);
    },
    commonQuantity() {
      return this.multiBasket.reduce(
        (sum, current) => sum + current.basketItems.reduce((sum, current) => sum + current.quantity, 0),
        0
      );
    },
    commonSum() {
      return this.multiBasket.reduce(
        (sum, current) => sum + current.basketItems.reduce((sum, current) => sum + getPrice(current) * current.quantity, 0),
        0
      );
    },
    /**
     * @return {ReturnsBasketItem[]|basketItem[]}
     */
    basketProducts() {
      return this.returnBasketId
        ? this.returnBasketItems
        : this.productsSort(this.basket, this.sort, 'basket');
    },
    priceUpdateVisible: {
      set() { },
      get() {
        return this.priceUpdate.visible;
      }
    },
    mainColumns() {
      if (this.windowSize.width > 1200) return [];
      if (this.windowSize.width > 1000) return ['index', 'article', 'name', 'price', 'reason', 'quantity', 'sum', 'del'];
      if (this.windowSize.width > 800) return ['index', 'article', 'name', 'quantity', 'reason', 'sum', 'del'];
      if (this.windowSize.width > 700) return ['index', 'article', 'name', 'quantity', 'reason', 'sum', 'del'];
      return ['index', 'article', 'name'];
    },
    shipmentDescription() {
      const basketProductsWithRegionalWarehouse = this.basketProducts.filter(
        product => product.warehouseId && product.warehouseId !== this._warehouseId
      );
      const basketMaxDeliveryDays = this.basketProducts.reduce((max, current) => {
        const currentValue = current.supplier ? JSON.parse(current.supplier).stocks.deliveryDays : 0;
        return max > currentValue ? max : currentValue;
      }, 0);
      return getDeliveryHint(
        {
          isRegionalWarehouse: basketProductsWithRegionalWarehouse.length > 0,
          deliveryDays: basketMaxDeliveryDays
        }
      );
    }
  },
  watch: {
    $route() {
      this.collapseFullOnNavigate();
    }
  },
  mounted() {
    this.collapseFullOnNavigate();
    window.addEventListener('returns-tour', () => {
      this.returnsTour = true;
    });
    window.addEventListener('returns-tour-end', () => {
      this.returnsTour = false;
    });
  },
  methods: {
    supplierCodeName,
    ...mapMutations('basket', ['SET_ITEM_PRICE', 'SET_ACTIVE_BASKET', 'RESET_PRICE_UPDATE']),
    ...mapMutations('ui', ['CHANGE_BASKET_SIZE']),
    ...mapMutations('products', ['SET_ANALOGS_FOR_ID']),
    ...mapMutations('returns', ['DELETE_RETURN_BASKET_ITEM']),
    ...mapActions('products', ['GetProductInfo']),
    ...mapActions('basket', ['checkDeliveryDate', 'DeleteProductInBasket']),
    ...mapActions('returns', [
      'PostReturnProducts',
      'DeleteItemFromReturnProductsBasket',
      'ChangeItemInReturnProductsBasket'
    ]),
    calcMax(row) {
      let count = 9999;
      if (row.supplier && typeof row.supplier === 'string') count = JSON.parse(row.supplier).stocks.count;
      if (row.supplier && typeof row.supplier === 'object') count = row.supplier.stocks.count;
      if (row.warehouseId === 1) count = row.stockBalance;
      if (row.warehouseId === 2) count = row.stockBalanceRegionalWarehouse;
      return count - (count % this.calcStep(row));
    },
    calcStep(row) {
      return +row.minShippingRate || 1;
    },
    collapseFullOnNavigate() {
      if (this.basketSize === 'big') {
        this.CHANGE_BASKET_SIZE('default');
      }
    },
    tableRowClassName(row) {
      if (this._warehouseId === 1) {
        if (+row.quantity > +row.stockBalance) {
          return 'warning-row';
        }
      }
      if (this._warehouseId === 2) {
        if (+row.quantity > +(row.stockBalance + row.stockBalanceRegionalWarehouse)) {
          return 'warning-row';
        }
      }
      return '';
    },
    deleteProduct(product) {
      this.$confirm('Вы уверены, что хотите удалить товар?', 'Удаление', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(() => {
          if (!this.returnBasketId) {
            this.DeleteProductInBasket({ basketItemId: product.id }).then(() => {
              this.$message({
                type: 'success',
                message: 'Товар удален',
              });
              this.$store.commit('products/SET_PRODUCT_BASKET_QUANTITY', {
                id: product.productId,
                basketId: this.activeBasketId,
                quantity: 0
              });
            }).catch((err) => {
              this.$message({
                type: 'error',
                message: `Не удалось удалить товар из корзины: ${err.message || err}`,
              });
            });
          } else {
            this.DeleteItemFromReturnProductsBasket(product.id).then(() => {
              this.DELETE_RETURN_BASKET_ITEM(product.id);
              this.$message({
                type: 'success',
                message: 'Товар удален',
              });
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено',
          });
        });
    },
    inputPutQuantity: debounce(async function inputPutQuantityFunc(basketItemId, quantity, product) {
      if (!this.returnBasketId) {
        await this.$store.dispatch('basket/PutChangeQuantityProduct', {
          basketItemId,
          quantity,
          supplier: typeof product.supplier === 'string' ? JSON.parse(product.supplier) : product.supplier,
          updateBasket: false,
        });
        this.$store.commit('products/SET_PRODUCT_BASKET_QUANTITY', {
          id: product.productId,
          basketId: this.activeBasketId,
          quantity
        });
      } else {
        await this.ChangeItemInReturnProductsBasket({
          returnProductsBasketItemId: basketItemId,
          quantity
        });
      }
    }),
    async goToOrder() {
      if (this.tourStatus) return;

      if (this.commonSum < this.minimumOrderSum) {
        this.$alert(`Сумма товаров в корзине должна быть не менее ${this.minimumOrderSum} ${this.userCurrencySign}`, 'Недостаточная сумма для заказа');
        return;
      }

      if (this.priceUpdate.visible) {
        this.notifyBzzz = true;
        setTimeout(() => {
          this.notifyBzzz = false;
        }, 700);
        return;
      }

      await this.checkDeliveryDate();

      this.reachGoal('toCheckout');
      await this.$router.push({ name: 'view-order' });
    },
    goToReturn() {
      this.$router.push({ name: 'return-order' });
    },
    supplierFilter(value) {
      if (value === null) return 'Николь';
      if (typeof value === 'string') {
        value = JSON.parse(value);
      }

      return value.supplier;
    },
    supplierDelivery(row) {
      const deliveryDate = getDeliveryDate({
        isRegionalWarehouse: row.warehouseId && row.warehouseId !== this._warehouseId,
        deliveryDays: row.supplier ? JSON.parse(row.supplier).stocks.deliveryDays : 0
      })?.deliveryDate;
      return deliveryDate ? formatDateNumeric(deliveryDate) : '';
    },
    parseSupplier(value) {
      if (typeof value === 'object') return value;
      return JSON.parse(value);
    },
    normalizePriceMarkup,
    normalizePrice,
    productsSort,
    getPrice,
  },
};
</script>

<style scoped lang="sass">
  @keyframes bzzz
    0%
      transform: translateX(0)
    10%
      transform: translateX(5px)
    20%
      transform: translateX(-10px)
    30%
      transform: translateX(15px)
    40%
      transform: translateX(-20px)
    50%
      transform: translateX(25px)
    60%
      transform: translateX(-20px)
    70%
      transform: translateX(15px)
    80%
      transform: translateX(-10px)
    90%
      transform: translateX(5px)
    100%
      transform: translateX(0)

  .basket-table
    display: flex
    flex-flow: column
    height: 100%
    hr
      position: relative
      border: none
      color: #e9e9e9
      background-color: #e9e9e9
      height: 1px
      margin: -1px 0 10px 0
      transition: .2s
      z-index: 1
    &_basket-size
      &-small
        hr
          opacity: 0
          margin: 0

  .router-link
    text-decoration: none

  .color-red
    color: red

  .basket-popover
    border-left: 3px solid #1f75a8
    &__title
      font-size: 14px
      display: flex
      flex-flow: nowrap
      align-items: center
      justify-content: space-between
      margin-bottom: 10px
    &_animation
      animation: bzzz .7s

  .basket__footer_position-bottom
    width: 100%
    background-color: #1f75a8
    z-index: 100
  .basket__footer_return
    background-color: #F56C6C
  .basket__footer
    display: flex
    justify-content: space-around
    padding: 5px
    align-items: center
    &__text-wrap
      flex: 1
      display: flex
      flex-flow: nowrap
      justify-content: space-between
      margin: 0 30px 0 5px

  .basket__footer-text
    margin: 0
    color: #ffffff

  .basket__footer-button
    cursor: pointer
    height: 30px

  .basket__footer-popover
    margin-right: 5px
    &__row
      display: flex
      flex-flow: nowrap
      font-size: 11px
      &_head > *
        font-weight: bold
        color: #909399
      &:not(:last-child)
        margin-bottom: 5px
    &__product-name
      margin-right: auto
    &__currency
      width: 75px
      text-align: right
      margin-left: 10px
      flex-shrink: 0

  .mb-baskets
    margin-bottom: 40px
  .resize-buttons
    margin-right: 15px
    display: grid
    align-items: center
    grid-template-columns: auto auto auto
    grid-gap: 16px
    margin-left: auto
    padding-left: 10px
    &__button
      color: #1C6A9E
      cursor: pointer
      font-weight: bold
      font-size: 13px
      border-bottom: 1px dashed rgba(28, 106, 158, 0.5)
      white-space: nowrap
  .delivery-question-icon
    color: #1C6A9E
    font-size: 14px
.small-product
  h2
    font-size: 12px
    margin: 5px 0
    display: flex
    align-items: center
    justify-content: space-between
    .el-button
      margin-left: 10px
  h3
    font-size: 11px
    color: #636363
    font-weight: 400
    margin: 0
  p
    font-size: 11px
    color: #282828
    margin: 2px 0 0
  &:not(:last-child)
    margin-bottom: 10px
  &__info
    display: flex
    flex-flow: nowrap
    justify-content: space-between
    gap: 10px
</style>
