<template>
  <el-col class="user-dropdown">
    <el-dropdown
      v-if="_role !== 'guest'"
      trigger="click"
    >
      <div class="el-dropdown-link">
        <div class="user-dropdown__button">
          <div
            class="user-dropdown__button__user-icon"
            :class="{ update }"
          >
            <i class="el-icon-s-custom" />
          </div>
          <p
            v-if="windowSize.width > 700"
            class="user-dropdown__button__clip"
          >
            <span v-text="name" />
            <span v-text="email" />
          </p>
          <div class="user-dropdown__button__icons">
            <i class="el-icon-arrow-down" />
          </div>
        </div>
      </div>
      <template #dropdown>
        <el-dropdown-menu class="menu">
          <el-dropdown-item v-if="_role === 'admin'">
            <span
              class="menu-link"
              @click="$router.push('/admin')"
            >Админ панель</span>
          </el-dropdown-item>
          <!--          <el-dropdown-item>-->
          <!--            <span class="menu-link" @click="$router.push('/order-items')">Отчёт по закупам</span>-->
          <!--          </el-dropdown-item>-->
          <!--          <el-dropdown-item>-->
          <!--            <span class="menu-link" @click="$router.push('/reconciliationReports')">Акты сверки</span>-->
          <!--          </el-dropdown-item>-->
          <el-dropdown-item
            v-if="update"
            class="menu-item-news"
          >
            <span
              ref="news"
              class="menu-link"
              @click="openChangelog"
            >Что нового?</span>
          </el-dropdown-item>
          <el-dropdown-item>
            <span
              class="menu-link"
              @click="personal"
            >Данные пользователя</span>
          </el-dropdown-item>
          <!--          <el-dropdown-item>-->
          <!--            <span class="menu-link" @click="$router.push('/shops')">Мои склады</span>-->
          <!--          </el-dropdown-item>-->
          <el-dropdown-item>
            <span
              class="menu-link"
              @click="$router.push('/garage')"
            >Гараж</span>
          </el-dropdown-item>
          <el-dropdown-item>
            <span class="menu-link">
              <a
                style="color: #606266;text-decoration: none;"
                :href="`${publicPath}help.docx`"
                download
              >Скачать инструкцию</a>
            </span>
          </el-dropdown-item>
          <el-dropdown-item>
            <span
              class="menu-link"
              @click="startTutorial"
            >Пройти обучение</span>
          </el-dropdown-item>
          <el-dropdown-item>
            <span
              class="menu-link"
              @click="logout"
            >Выход</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-button
      v-else
      class="exit-button"
      size="small"
      type="warning"
      @click="logout"
    >
      Выход
    </el-button>
  </el-col>
</template>

<script>
import ymMixin from 'utils/ymMixin';
import {
  mapActions, mapGetters, mapMutations, mapState
} from 'vuex';

export default {
  name: 'AppHeaderUserDropdown',
  mixins: [ymMixin],
  data() {
    return {
      update: false,
      changelog: [],
      publicPath: import.meta.env.BASE_URL
    };
  },
  computed: {
    ...mapGetters('auth', ['_role']),
    ...mapState('accounts', ['name', 'email']),
    ...mapState('ui', ['windowSize']),
  },
  async created() {
    setTimeout(() => {
      this.GetLastUpdates()
        .then((data) => {
          this.changelog = data?.updates || [];
          if (data?.read === false) this.update = true;
        })
        .catch((err) => {
          console.error('Ошибка загрузки списка обновлений', err);
        });
    }, 1000);
  },
  methods: {
    ...mapActions('accounts', ['GetLastUpdates', 'SetLastUpdateId']),
    ...mapActions('auth', ['AuthLogout']),
    ...mapMutations('ui', ['SET_COLLAPSE_MENU', 'SET_SHOW_COLUMNS']),
    async startTutorial() {
      this.reachGoal('tutorial');
      if (this.$route.name === 'view-order') {
        localStorage.removeItem('tourOrder');
        window.location.pathname = '/products';
      } else {
        localStorage.removeItem('tour');
        this.SET_COLLAPSE_MENU(false);
        this.SET_SHOW_COLUMNS({
          hide: true,
          hide2: true,
          hide3: false,
          hide4: true,
          hide5: true,
        });

        window.location.pathname = '/products';
      }
    },
    logout() {
      this.AuthLogout();
      this.$router.push({ name: 'app-login' });
    },
    personal() {
      this.$router.push('/settings');
    },
    async openChangelog() {
      let html = '';
      this.changelog.forEach((item) => {
        html += `<div class="log-block">${item.text}</div>`;
      });
      await this.$alert(html, 'История обновлений', {
        dangerouslyUseHTMLString: true,
        customClass: 'log-block__wrapper',
      })
        .then(() => {
          this.update = false;
          this.SetLastUpdateId(this.changelog[0].id);
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="sass">
.log-block
  max-height: 300px
  overflow: auto
  padding: 0 10px
  &__wrapper
    width: 600px
    .el-message-box__content
      padding: 10px 0
</style>

<style scoped lang="sass">
.user-dropdown
  height: 42px
  display: flex
  flex-flow: nowrap
  align-items: center
  justify-content: center
  &__button
    display: flex
    margin-left: 5px
    cursor: pointer
    align-items: center
    height: 42px
    &__user-icon
      position: relative
      display: block
      margin-right: 10px
      height: 18px
      width: 18px
      @media (max-width: 800px)
        margin-right: 0
      &.update::after
        content: ''
        display: block
        position: absolute
        top: -2px
        right: -2px
        height: 6px
        width: 6px
        background: yellow
        border-radius: 50%
        box-shadow: 0 1px 3px rgba(yellow, .2)
    .el-icon-s-custom
      flex-shrink: 0
      flex-grow: 0
      font-size: 18px
    &__clip
      display: flex
      flex-flow: column
      margin: 0
      span
        &:first-child
          color: #fff
          font-size: 12px
          margin-right: 13px
        &:last-child
          font-size: 10px
          color: #fff
    &__icons
      padding: 4px
      font-size: 20px
      color: #fff
      display: flex
      align-items: center
      .el-icon-arrow-down
        margin-right: -4px
        font-size: 14px
.menu
  .el-dropdown-menu__item
    padding: 2px 50px 2px 20px
  .menu-item-news
    color: white
    background: #1f75a8
    .menu-link
      color: white
.menu-link
  display: block
  color: rgb(96, 98, 102)
  text-decoration: none
  white-space: pre
  width: 100%
  &.active
    font-weight: bold

.exit-button
  margin-left: 10px

</style>
