<template>
  <div class="personal">
    <div class="reports-page">
      <page-header class="header">
        Отчёты
        <template #subtitle>
          Создание Excel отчёта по действиям пользователя
        </template>
      </page-header>


      <div class="reports-form">
        <div class="field">
          <div>Пользователь:</div>
          <el-select
            v-model="accountId"
            filterable
            clearable
            remote
            placeholder="Начните вводить имя"
            :remote-method="getAccounts"
            :loading="accountsLoading"
            no-data-text="Пользователи не найдены"
            style="width: 350px"
          >
            <el-option
              v-for="account in accounts"
              :key="account.id"
              :label="account.name"
              :value="account.id"
            />
          </el-select>
        </div>
        <div class="field">
          <div>
            Дата документа:
          </div>
          <div class="block">
            <el-date-picker
              v-model="date"
              class="date-picker-history"
              type="daterange"
              size="mini"
              align="right"
              style="width: 350px"
              unlink-panels
              range-separator="|"
              start-placeholder="С"
              end-placeholder="По"
              :picker-options="pickerOptions"
              value-format="yyyy.MM.dd"
            />
          </div>
        </div>

        <div class="field">
          <div>Тип отчёта:</div>
          <el-select
            v-model="type"
            size="small"
            style="width: 350px"
          >
            <el-option
              label="Все действия"
              value="actions"
            />
            <el-option
              label="Действия с товарами"
              value="actions_with_product"
            />
            <el-option
              label="Рекомендации"
              value="recommendations"
            />
            <el-option
              label="Рекомендации+"
              value="recommendations+"
            />
          </el-select>
        </div>
      </div>
      <el-button
        type="primary"
        icon="el-icon-download"
        :loading="reportLoading"
        @click="downloadReport"
      >
        Скачать
      </el-button>
    </div>
  </div>
</template>

<script>
import axiosInstance from 'api/axiosInstance.js'
import { mapActions, mapState } from "vuex";

import PageHeader from "@/components/page-header/page-header.vue";

const pickerOptions = {
  shortcuts: [{
    text: 'Последняя неделя',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end]);
    },
  }, {
    text: 'Последний месяц',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    },
  }, {
    text: 'Последние 3 месяца',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    },
  }],
};

export default {
  name: "Reports",
  components: { PageHeader },
  metaInfo: {
    title: "Отчёты",
  },
  data() {
    return {
      type: 'actions',
      userId: null,
      accountId: null,
      pickerOptions,
      date: [],
      accounts: [],
      accountsLoading: false,
    };
  },
  computed: {
    ...mapState("documents", ["reportLoading"]),
  },
  mounted() {

  },
  methods: {
    ...mapActions("documents", ["getReport"]),
    async getAccounts(searchString) {
      this.accountsLoading = true;
      await axiosInstance({
        url: '/api/admin/accounts',
        params: {
          max: 10,
          offset: 0,
          name: searchString,
        }
      }).then(data => {
        this.accounts = data;
      }).catch((err) => {
        this.$message.error("Не удалось загрузить пользователей");
        console.error("Ошибка загрузки списка пользователей", err);
      }).finally(() => {
        this.accountsLoading = false;
      })
    },
    async downloadReport() {
      const getDate = dateString => (dateString ? dateString.replaceAll('.', '-') : null);

      await this.getReport(
        {
          type: this.type,
          userId: this.userId,
          accountId: this.accountId,
          from: getDate(this.date[0]),
          to: getDate(this.date[1]),
        }
      ).then(data => {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.xlsx');
        document.body.appendChild(link);
        link.click();
      }).catch((err) => {
        this.$message.error("Не удалось скачать отчёт");
        console.error("Ошибка скачивания отчёта", err);
      })
    }
  },
};
</script>

<style lang="sass" scoped>
.reports-page
  max-width: 800px
  margin-left: auto
  margin-right: auto
  padding-bottom: 40px
.reports-form
  display: flex
  flex-direction: column
  gap: 15px
  align-items: flex-start
  margin: 20px 0
.field
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 5px
.header
  margin-bottom: 20px

</style>
