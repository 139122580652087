import axiosInstance from "api/axiosInstance";

export default {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    logHover(_, productId) {
      return axiosInstance({
        url: '/api/events',
        method: 'post',
        data: {
          type: 'hoverMouseOnProduct',
          productId
        }
      })
        .finally(() => {
          //
        })
    },
  }
}
