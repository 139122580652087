import axiosInstance from 'api/axiosInstance.js'

const state = () => ({
  type: 'act',
  types: [],
  loading: false,
  documents: [],
  /** @type {'createdAt'|'type'|'status'} */
  sortField: 'createdAt',
  sortOrder: 'desc',
  statuses: {
    'IN WORK': 'В работе',
    'DONE': 'Обработан',
  },
  reportLoading: false,
});

const mutations = {
  SET_DOCUMENTS(state, documents) {
    state.documents = documents;
  },
  SET_LOADING(state, loadingState) {
    state.loading = loadingState;
  },
  SET_TYPE(state, type) {
    state.type = type;
  },
  SET_TYPES(state, types) {
    state.types = types;
  },
  SET_SORT_FIELD(state, sortField) {
    state.sortField = sortField;
  },
  SET_SORT_ORDER(state, sortOrder) {
    state.sortOrder = sortOrder;
  },
  SET_REPORT_LOADING(state, loadingState) {
    state.reportLoading = loadingState;
  },
};
const actions = {
  async createDocument(_, type) {
    return await axiosInstance.post('/api/documents', {
      type
    });
  },
  async getDocuments({ state, commit }) {
    commit('SET_LOADING', true);
    await axiosInstance.get('/api/documents', {
      params: {
        type: state.type,
        max: 50,
        offset: 0,
        sortOrder: state.sortOrder,
        sortField: state.sortField
      }
    })
      .then((response) => {
        commit('SET_DOCUMENTS', response);
      }).finally(() => {
        commit('SET_LOADING', false);
      });
  },
  async getDocumentsTypes({ commit }) {
    await axiosInstance.get('/api/documents/types')
      .then((resp) => {
        commit('SET_TYPES', resp);
      });
  },
  async getReport({ commit }, {type, from, to, userId, accountId }) {
    commit('SET_REPORT_LOADING', true);
    return await axiosInstance.get('/api/admin/data/reports', {
      params: {
        from,
        to,
        type,
        userId,
        accountId,
      },
      responseType: 'blob'
    }).finally(() => {
        commit('SET_REPORT_LOADING', false);
      });
  },
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
