<template>
  <div
    class="tab-line"
    :class="b({ basketSize })"
  >
    <p
      v-if="windowSize.width > 700"
      class="tab-button_first"
    >
      Выбор корзины:
    </p>
    <div
      v-for="basket in multiBasketData"
      :key="basket.id"
      :class="['tab-button', { active: activeTabData === basket.id }]"
      :style="{ borderColor: getColor(basket.id, activeTabData === basket.id) }"
    >
      <div class="tab-button__paragraph">
        <p
          class="tab-button__name"
          @click="tabClickHandler(basket)"
        >
          {{ basket.name }}
        </p>

        <span
          v-show="basket.id === activeTabData"
          id="v-step-7"
          class=" tab-button__actions"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="Удалить корзину"
            placement="top"
          >
            <i
              class="el-icon-delete"
              @click="clickDeleteBasket(activeTabData)"
            />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Изменить название"
            placement="top"
          >
            <i
              class="el-icon-edit"
              @click="dblClickRenameTab(basket)"
            />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Очистить корзину"
            placement="top"
          >
            <i
              class="el-icon-brush"
              @click="clearBasket(activeTabData)"
            />
          </el-tooltip>
        </span>
      </div>
    </div>
    <el-button
      id="v-step-6"
      type="info"
      size="mini"
      plain
      icon="el-icon-plus"
      circle
      @click="buttonClickHandler"
    />

    <div
      v-if="returnBaskets.length || returnsTour"
      class="return-baskets v-step-return-basket"
    >
      <div
        v-for="basket in returnBaskets"
        :key="basket.id"
        :class="['tab-button', { active: returnBasketId === basket.id }, 'tab-button_return']"
      >
        <div class="tab-button__paragraph">
          <p
            class="tab-button__name"
            @click="returnBasketTabClickHandler(basket)"
          >
            {{ basket.name }}
          </p>

          <span
            v-show="basket.id === returnBasketId"
            id="v-step-7"
            class=" tab-button__actions"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="Удалить корзину"
              placement="top"
            >
              <i
                class="el-icon-delete"
                @click="clickDeleteReturnBasket(returnBasketId)"
              />
            </el-tooltip>
          </span>
        </div>
      </div>
      <div
        v-if="returnsTour"
        class="tab-button active tab-button_return"
      >
        <div class="tab-button__paragraph">
          <p class="tab-button__name">
            Корзина возврата
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'ChangeBasketButtons',
  props: {
    basketSize: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      returnsTour: false,
    };
  },
  computed: {
    ...mapState('basket', {
      multiBasketData: state => state.multiBasket,
      activeTabData: 'activeBasketId',
      basket: 'basket'
    }),
    ...mapState('products', ['tourStatus']),
    ...mapState('ui', ['userCurrency', 'windowSize']),
    ...mapState('returns', {
      returnBaskets: state => state.baskets,
      returnBasketId: state => state.basketId,
    }),
  },
  mounted() {
    window.addEventListener('returns-tour', () => {
      this.returnsTour = true;
    });
    window.addEventListener('returns-tour-end', () => {
      this.returnsTour = false;
    });
  },
  methods: {
    ...mapActions('basket', [
      'SetActiveBasketData',
      'GetProductsBasket',
      'DeleteBasket',
      'PutRenameBasket',
      'ClearBasket',
      'CreateBasket'
    ]),
    ...mapActions('returns', ['GetReturnProductsBasket', 'DeleteReturnProductBasket']),
    ...mapMutations('products', [
      'SET_PRODUCT_BASKET_QUANTITY',
      'DELETE_BASKET',
      'SET_PRODUCT_BASKET_QUANTITY'
    ]),
    ...mapMutations('basket', ['SET_ACTIVE_BASKET']),
    ...mapMutations('returns', ['SET_RETURN_BASKET_ID', 'DELETE_RETURN_BASKET']),
    getColor(id, active) {
      const { colour } = this.multiBasketData.find(item => item.id === Number(id));
      if (!colour) return 'unset';
      return active ? `rgb(${colour})` : `rgba(${colour}, 0.8)`;
    },
    tabClickHandler(basket) {
      this.currentTab = basket;
      const basketId = basket.id;
      if (basketId !== this.activeTabData) {
        this.SET_RETURN_BASKET_ID(null);
        this.SET_ACTIVE_BASKET(basketId);
        this.GetProductsBasket().catch((err) => {
          this.$message.error('Не удалось загрузить товары корзины');
          console.error(err);
        });
      }
    },
    returnBasketTabClickHandler(basket) {
      this.currentTab = basket;
      const basketId = basket.id;
      if (basketId !== this.activeTabData) {
        this.SET_ACTIVE_BASKET(null);
        this.SET_RETURN_BASKET_ID(basketId);
        this.GetReturnProductsBasket(basketId);
      }
    },
    clickDeleteBasket(basketId) {
      if (this.tourStatus) return;

      if (this.multiBasketData.length === 1) {
        this.$alert('Невозможно удалить последнюю корзину.', 'Удаление', {
          confirmButtonText: 'OK',
        });
        return;
      }

      this.$confirm('Вы уверены, что хотите удалить корзину? Без возможности восстановления', 'Удаление', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(() => {
          this.basket.forEach((item) => {
            this.SET_PRODUCT_BASKET_QUANTITY({
              id: item.productId,
              basketId: this.activeTabData,
              quantity: 0
            });
          });
          this.DeleteBasket(basketId)
            .then(() => {
              setTimeout(() => {
                this.tabClickHandler(this.multiBasketData[this.multiBasketData.length - 1]);
              }, 500);
              this.DELETE_BASKET(String(basketId));
            });

          this.$message.success('Удалено');
        })
        .catch(() => {
          this.$message.info('Удаление отменено');
        });
    },
    clickDeleteReturnBasket(basketId) {
      this.$confirm('Вы уверены, что хотите удалить корзину возврата? Без возможности восстановления', 'Удаление', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(() => {
          this.DeleteReturnProductBasket(basketId)
            .then(() => {
              setTimeout(() => {
                this.tabClickHandler(this.multiBasketData[this.multiBasketData.length - 1]);
              }, 500);
              this.DELETE_RETURN_BASKET(String(basketId));
              this.$message.success('Удалено');
            });
        })
        .catch(() => {
          this.$message.info('Удаление отменено');
        });
    },
    dblClickRenameTab(basket) {
      if (!this.tourStatus) {
        const basketId = basket.id;
        const basketName = basket.name;

        this.$prompt('Введите название корзины (от 3 до 30 символов)', 'Изменение', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Отмена',
          inputValue: basketName,
          inputPlaceholder: 'Введите название корзины',
          inputValidator: value => value.length >= 3 && value.length <= 30,
          inputErrorMessage: 'Название корзины не соответствует требованиям',
        })
          .then(({ value }) => {
            this.PutRenameBasket({
              basketId,
              name: value,
            })
              .then(() => {
                this.$message({
                  type: 'success',
                  message: `Изменение название корзины: ${value}`,
                });
              })
              .catch((e) => {
                this.$message.error(e.message);
              });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'Изменение отменено',
            });
          });
      }
    },
    async clearBasket() {
      if (this.tourStatus) return;
      this.$confirm('Вы уверены, что хотите очистить корзину? Без возможности восстановления', 'Очистка', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(() => {
          this.basket.forEach((item) => {
            this.SET_PRODUCT_BASKET_QUANTITY({
              id: item.productId,
              basketId: this.activeTabData,
              quantity: 0
            });
          });
          this.ClearBasket({ basketId: this.activeTabData });
          this.$message({
            type: 'success',
            message: 'Корзина очищена',
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Очистка отменена',
          });
        });
    },
    buttonClickHandler() {
      if (this.tourStatus) return;
      if (this.multiBasketData.length >= 10) {
        this.$alert(
          'Вы не можете создавать более 10 корзин. Пожалуйста, оформите заказ из существующей корзины или удалите одну из них.',
          'Создание',
          {
            confirmButtonText: 'OK',
          }
        );
        return;
      }
      this.$prompt('Введите название корзины (от 3 до 30 символов)', 'Создание', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Отмена',
        inputValue: 'Корзина',
        inputPlaceholder: 'Введите название корзины',
        inputValidator: value => value.length >= 3 && value.length <= 30,
        inputErrorMessage: 'Название корзины не соответствует требованиям',
      })
        .then(({ value }) => {
          this.CreateBasket(value)
            .then(() => {
              this.$message({
                type: 'success',
                message: `Новая корзина: ${value}`,
              });
            })
            .catch((err) => {
              this.$message.error(err);
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Создание отменено',
          });
        });
    },
  }
};
</script>

<style scoped lang="sass">
  .change-basket-buttons
    position: relative
    display: flex
    flex-flow: nowrap
    align-items: center
    height: 40px
    padding: 10px 10px 0
    overflow-y: hidden
    overflow-x: auto
    z-index: 2
    &_basket-size
      &-small
        height: 50px
        padding: 10px
        .tab-button
          border-radius: 5px
          margin-right: 5px
          border: 1px solid
          &.active
            border: 1px solid
            border-left: 5px solid
            box-shadow: 0 0 2px rgba(0, 0, 50, 0.2)

  .tab-button
    max-height: 30px
    font-size: 12px
    font-family: "Nunito", sans-serif
    margin-right: 2px
    border-top-left-radius: 7px
    border-top-right-radius: 7px
    border: 0
    border-top: 3px solid #1f75a8
    cursor: pointer
    color: #8898aa
    transition: .2s
    background-color: #f3f3f3
    overflow: hidden
    display: block
    min-width: 70px
    margin-bottom: -1px
    &:hover
      color: #8898aa
    &_first
      min-width: 127px
      max-height: 35px
      color: #000
      padding: 6px 3px 6px 10px
      font-weight: bold
      font-size: 12px
      font-family: "Nunito", sans-serif
      margin: 0
    &__paragraph
      margin: 0
      display: flex
      align-content: center
      align-items: center
      justify-content: space-between
      .active &
        margin: 0
        display: flex
        min-width: 150px
    &.active
      color: #000
      border-bottom-color: #fff
      background-color: #fff
      width: max-content
      box-shadow: 0 -1px 2px rgba(0, 0, 50, 0.2)
      min-width: 170px
    &_return, &_return.active
      background: #f56c6c
      color: #fff
      border-color: rgb(255, 135, 135)
      &:hover
        color: #fff
    &_button
      cursor: pointer
      border-radius: 5px
      padding: 5px 10px
      font-size: 11px
      line-height: 1.5
      border: 1px solid #ccc
      > .tab-close
        opacity: 0
        width: 8px
        height: 8px
        transform: translate(-1px, 0)
        transition: .2s
        &:hover
          transform: scale(1.4)
          border-radius: 50%
          background-color: #ccc
    &__name
      margin: 0
      padding: 5px
    &__actions
      margin-right: 5px
    &__actions i
      margin-left: 7px
      &:hover
        font-weight: bold
    &__paragraph .tab-button__name
      width: 60px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

  .active .tab-button__paragraph .tab-button__name
    width: max-content
    white-space: unset
    overflow: no-content
    text-overflow: unset

  .active:hover > .tab-close_button > .tab-close
    opacity: 1
    transform: translate(1px, 0)
.return-baskets
  display: flex
  flex-flow: nowrap
  margin-left: 20px
  padding-left: 20px
  border-left: 1px solid #ccc
</style>
