<template>
  <el-container class="products-container">
    <app-aside @selected="updateBreadcrumbs(categories, $event)" />

    <el-main class="wrapper">
      <div class="table-card">
        <div class="breadcrumb-container">
          <div class="breadcrumbs">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item
                v-for="(item, index) in breadcrumb"
                :key="index"
              >
                <router-link
                  class="breadcrumb-item"
                  :to="'/products' + (item.id ? '/' + item.id : '')"
                >
                  {{ item.name }}
                </router-link>
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>

          <div style="display: flex; align-items: center;gap:5px">
            <strong>Версия </strong><span
              v-if="productListVersion === 2"
              style="color: green; font-weight: bold"
            >2</span>
            <span
              v-else
              style="color: red"
            >1</span>

            <el-button
              size="mini"
              type="primary"
              @click="toggleVersion"
            >
              Переключить
            </el-button>
          </div>

          <div class="breadcrumb-container__text">
            <p class="found">
              Найдено
              <b v-if="productsCount">{{ productsCount.toLocaleString() }}</b>
              в разделе <b>{{ breadcrumb[breadcrumb.length - 1].name }}</b>
            </p>
          </div>
          <div style="display: flex; gap: 10px">
            <el-button
              type="info"
              size="mini"
              plain
              icon="el-icon-refresh-left"
              class="return-button v-step-return"
              @click="returnButtonClickHandler"
            >
              оформить возврат
            </el-button>
            <el-tooltip
              effect="dark"
              content="Показывать изображения товаров"
            >
              <el-button
                id="catalog-images-toggle"
                class="breadcrumb-container__button"
                size="mini"
                icon="el-icon-picture"
                :type="displayCatalogImages ? 'primary': 'default'"
                @click="SET_DISPLAY_CATALOG_IMAGES(!displayCatalogImages)"
              />
            </el-tooltip>
            <el-button
              class="breadcrumb-container__button v-step-4"
              size="mini"
              icon="el-icon-s-tools"
              @click="SET_SHOW_SETTINGS(true)"
            >
              Настройки
            </el-button>
          </div>
        </div>

        <nikol-products-tables
          v-if="tableType === 'nikolProducts'"
          :loading-index="productCartLoadingIndex"
          @addProduct="addProduct($event)"
          @categoryChanged="updateBreadcrumbs(categories, $event)"
        />
      </div>
      <div
        v-if="abSegment === 'A'"
        class="recommendations"
      >
        <div
          v-if="!showRecommendations"
          class="recommendations-title-link"
          @click="toggleRecommendations"
        >
          Может быть интересно
        </div>
        <div
          v-else
          style="display: flex; justify-content: space-between;"
        >
          <div class="recommendations-title">
            Может быть интересно
          </div>
          <el-button
            icon="el-icon-close"
            size="mini"
            style="align-self: flex-end"
            @click="toggleRecommendations"
          />
        </div>

        <recommendations-block v-if="showRecommendations" />
      </div>

      <basket-aside />
    </el-main>

    <settings-dialog v-if="showSettings" />
  </el-container>
</template>

<script>
import Shepherd from 'shepherd.js';
import tour from 'utils/tour';
import ymMixin from 'utils/ymMixin';
import {
  mapActions, mapGetters, mapMutations, mapState
} from 'vuex';

import AppAside from '@/components/app-tree/app-aside';
import BasketAside from '@/components/basket-aside/basketAside';
import SettingsDialog from '@/components/product-table/layout/settingsDialog';
import RecommendationsBlock from "@/components/recommendations.vue";
import NikolProductsTables from '@/views/products/components/NikolProductsTable';

export default {
  name: 'ViewProducts',
  components: {
    RecommendationsBlock,
    SettingsDialog,
    NikolProductsTables,
    BasketAside,
    AppAside,
  },
  mixins: [ymMixin],
  metaInfo: {
    title: 'Товары'
  },
  data() {
    return {
      breadcrumb: [{
        name: 'Товары Николь',
        id: 0,
      }],
      showRecommendations: JSON.parse(localStorage.getItem('showRecommendations')) ?? true
    };
  },
  computed: {
    ...mapGetters('categories', ['categories']),
    ...mapGetters('auth', ['abSegment']),
    ...mapState('tecdoc', ['modelId', 'manufacturerId']),
    ...mapState('products', [
      'tableType',
      'products',
      'productsCount',
      'supplierProductsInfo',
      'filters',
      'productCartLoadingIndex',
      'search',
      'productListVersion'
    ]),
    ...mapState('basket', ['multiBasket']),
    ...mapState('returns', {
      returnBasketId: state => state.basketId,
      returnBaskets: state => state.baskets,
    }),
    ...mapState('ui', ['displayCatalogImages', 'showSettings']),
  },
  async mounted() {
    if (!localStorage.getItem('tour')) {
      this.$confirm('Хотите пройти обучение и ознакомиться с основными функциями сайта?', 'Обучение', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        type: 'info',
      })
        .then(() => {
          this.reachGoal('startTour');
          this.$nextTick(() => {
            this.$store.commit('products/SET_TOUR_STATUS', true);
            this.SET_COLLAPSE_MENU(false);

            tour.on('complete', () => {
              this.showTourHelp();
              this.reachGoal('endTour');
              localStorage.setItem('tour', 'true');
              this.$store.commit('products/SET_TOUR_STATUS', false);
            });

            tour.on('cancel', () => {
              this.showTourHelp();
              localStorage.setItem('tour', 'true');

              this.$store.commit('products/SET_TOUR_STATUS', false);
            });
            tour.start();
          });
        })
        .catch(() => {
          this.showTourHelp();
          localStorage.setItem('tour', 'true');
        });
    }
    if (!localStorage.getItem('images-tour')) {
      this.$nextTick(() => {
        const imagesTour = new Shepherd.Tour({
          defaultStepOptions: {
            classes: 'shadow-md bg-purple-dark',
            cancelIcon: {
              enabled: true,
            },
          },
          useModalOverlay: true,
          confirmCancel: false
        });

        imagesTour.addStep({
          attachTo: { element: '#catalog-images-toggle', on: 'bottom' },
          title: 'Новая функция',
          text: 'Нажмите на кнопку, чтобы изменить вид каталога',
          buttons: [
            {
              text: 'Хорошо',
              action: imagesTour.complete
            }
          ],
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 12] } }]
          }
        });
        imagesTour.start();
        imagesTour.on('complete', () => {
          localStorage.setItem('images-tour', 'true');
        });
      });
    }
    if (this.tableType !== 'nikolProducts') {
      const { brand, article } = this.supplierProductsInfo;
      if (brand && article && this.$route.name !== 'view-products') {
        await this.$router.replace(`/part/${brand}/${article}`);
      } else {
        this.SET_PRODUCT_TABLE_TYPE('nikolProducts');
      }
    }
  },
  beforeDestroy() {
    this.RESET_PRODUCTS();
  },
  methods: {
    ...mapMutations('products', [
      'RESET_PRODUCTS',
      'SET_PRODUCT_TABLE_TYPE',
      'SET_PRODUCT_BASKET_QUANTITY',
      'SET_PRODUCT_CART_LOADING_INDEX',
      'SET_PRODUCT_LIST_VERSION',
    ]),
    ...mapMutations('ui', ['SET_SHOW_SETTINGS', 'SET_DISPLAY_CATALOG_IMAGES', 'SET_COLLAPSE_MENU']),
    ...mapActions('basket', ['AddProductInBasket']),
    ...mapActions('products', ['GetProductsList']),
    ...mapActions('returns', ['AddReturnProductsBasket', 'AddItemToReturnProductsBasket', 'activateReturnBasket', 'basketHasProductId']),
    toggleRecommendations() {
      localStorage.setItem('showRecommendations', !this.showRecommendations)
      this.showRecommendations = !this.showRecommendations;
    },
    handleAddProductErrors(errorMessage) {
      this.$alert(errorMessage, 'Добавление в корзину', {
        confirmButtonText: 'OK',
      });
    },
    async addProduct(product) {
      this.loadingIndex = product.id;
      this.SET_PRODUCT_CART_LOADING_INDEX(product.id);
      if (this.returnBasketId) {
        if (await this.basketHasProductId(product.id)) {
          this.$confirm(`Товар "${product.name}" уже есть в корзине, хотите добавить еще один?`, 'Добавление', {
            confirmButtonText: 'Да',
            cancelButtonText: 'Отмена',
            type: 'warning'
          }).then(async () => {
            await this.addReturnProduct(product.id || product.supplier, product.quantity).catch(() => {
              //
            });
          }).catch(() => {
            //
          });
        } else {
          await this.addReturnProduct(product.id || product.supplier, product.quantity).catch(() => {
            //
          });
        }
      } else {
        await this.AddProductInBasket(product)
          .catch((message) => {
            this.handleAddProductErrors(message);
          });
      }
      product.quantity = +product.minShippingRate;
      this.loadingIndex = null;
      this.SET_PRODUCT_CART_LOADING_INDEX(null);
    },
    async addReturnProduct(productId, quantity) {
      return this.$prompt('Укажите причину возврата товара', 'Возврат', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Отмена',
        inputPlaceholder: 'Причина возврата',
        inputValidator: value => value !== null && value.length >= 3 && value.length <= 30,
        inputErrorMessage: 'Комментарий должен содержать от 3 до 20 символов',
      })
        .then(async ({ value }) => {
          await this.AddItemToReturnProductsBasket({
            productId,
            quantity,
            returnProductsBasketId: this.returnBasketId,
            reason: value
          })
            .catch((message) => {
              this.handleAddProductErrors(message);
            });
        });
    },
    updateBreadcrumbs(categories, catId) {
      if (catId[0] === 'l') return;
      this.breadcrumb = [];
      this.catSearch(categories, catId);
      if (catId) {
        this.breadcrumb.unshift({
          name: 'Товары Николь',
          id: 0,
        });
      }
    },
    catSearch(categories, catId) {
      categories.forEach((item) => {
        if (+item.id === +catId) {
          this.breadcrumb.unshift(item);
          if (item.parentId) this.catSearch(this.categories, item.parentId);
          return;
        }
        if (item.children) {
          this.catSearch(item.children, catId);
        }
      });
    },
    returnButtonClickHandler() {
      if (this.returnBaskets.length) {
        this.activateReturnBasket(this.returnBaskets[0].id);
        return;
      }
      this.$prompt('Введите название корзины (от 3 до 30 символов)', 'Создание корзины возврата', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Отмена',
        inputValue: 'Корзина возврата',
        inputPlaceholder: 'Введите название корзины',
        inputValidator: value => value.length >= 3 && value.length <= 30,
        inputErrorMessage: 'Название корзины не соответствует требованиям',
      })
        .then(({ value }) => {
          this.AddReturnProductsBasket(value);
        });
    },
    showTourHelp() {
      this.$alert('Вы сможете запустить обучение в любое время из меню "Помощь"', 'Обучение', {
        confirmButtonText: 'OK',
      }).catch(() => {
        //
      });
    },
    async toggleVersion() {
      this.SET_PRODUCT_LIST_VERSION(this.productListVersion === 2 ? undefined : 2);
      await this.GetProductsList().catch((error) => {
        this.$message.error('Не удалось загрузить товары после смены версии. ' + error);
        console.error('Ошибка загрузки товаров после смены версии', error)
      });
    }
  },
};
</script>

<style scoped lang="sass">
  .products-container
    height: calc(100% - 42px)
    position: fixed
    width: 100%
  .wrapper
    display: flex
    flex-flow: column
    height: 100%
    overflow: hidden
  .found
    margin: 0

  .breadcrumb-item
    cursor: pointer
    font-size: 12px
    font-weight: normal

  .breadcrumb-container
    margin: -2px 0 3px
    padding-bottom: 5px
    font-size: 12px
    display: grid
    grid-template-columns: 1fr auto auto auto auto
    gap: 15px
    align-items: center
    justify-items: start

    @media (max-width: 700px)
      grid-template-columns: 1fr
      align-items: start
      &__button
        margin-left: 0 !important

  .breadcrumbs
    margin-right: auto

  .table-card
    padding: 10px
    flex: 1
    display: flex
    flex-direction: column
    overflow: hidden
    background-color: #fff

  .return-button
    height: 20px
  .search-in-catalogs-message
    background: #def0ff
    font-size: 14px
    padding: 10px 15px
    border-radius: 4px
  .recommendations
    display: flex
    flex-direction: column
    border-top: 1px solid #EBEEF5
    gap: 10px
    padding: 20px
    overflow: hidden
  .recommendations-title
    font-size: 12px
    font-weight: 700
  .recommendations-title-link
    font-size: 12px
    font-weight: 700
    border-bottom: 1px dashed #1C6A9E
    color: #1C6A9E
    cursor: pointer
    align-self: flex-start
</style>
